<form [formGroup]="imputerForm" (ngSubmit)="onSubmit()" class="normal-form">
    <div fxLayout="row">
        <div fxFlex="70">
            <h2>DIVISIONS ET BUREAUX</h2>
            <mat-grid-list cols="1" rowHeight="300px">
                <mat-grid-tile>
                    <div class="controles-container">
                        <mat-checkbox formControlName="etudesInnovationsPedagogiques">Etudes et innovations pedagogiques</mat-checkbox>
                        <mat-checkbox formControlName="accueilStages">Bureau accueil</mat-checkbox>
                        <mat-checkbox formControlName="intendance">Intendance</mat-checkbox>
                        <mat-checkbox formControlName="surveillantGeneral">Surveillant general</mat-checkbox>
                        <mat-checkbox formControlName="programmeUtilisationTerrainsGazonnes">Pelouse et terrain</mat-checkbox>
                        <mat-checkbox formControlName="surveillantGeneral">Emploi des salles</mat-checkbox>
                        <mat-checkbox formControlName="programmeUtilisationTerrainsGazonnes">Entretien et maintenance</mat-checkbox>
                        <mat-checkbox formControlName="entretientMaintenance">Hebergement/Digital Manager</mat-checkbox>
                        <mat-checkbox formControlName="surveillantGeneral">Comptabilite des matieres</mat-checkbox>
                        <mat-checkbox formControlName="accueilStages">Personnel et solde</mat-checkbox>
                        <mat-checkbox formControlName="assistantDirection">Assistant de direction</mat-checkbox>
                    </div>
                </mat-grid-tile>
            </mat-grid-list>
        </div>
        <div fxFlex="50">
            <h2>POUR:</h2>
            <mat-grid-list cols="1" rowHeight="300px">
                <mat-grid-tile>
                    <div class="controles-container">
                        <mat-form-field>
                            <input formControlName="autres" matInput placeholder="Indications" >
                            <mat-error>Ce champ est requis !</mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input type="date" formControlName="echeance" matInput placeholder="Echeance" >
                            <mat-error>Ce champ est requis !</mat-error>
                        </mat-form-field>
                    </div>
                </mat-grid-tile>
            </mat-grid-list>
        </div>
    </div>

    <button style="position: relative ; float: right" [disabled]="imputerForm.invalid" mat-raised-button color="primary" type="submit">Valider</button>
</form>
