<div fxlayout="row wrap" fxLayoutAlign="start start">
    <div fxFlex="100">
        <div fxlayout="row" fxLayoutAlign="center center">
            <h2>Details du dossier</h2>
        </div>
    </div>
</div>
<div fxlayout="row wrap" fxLayoutAlign="start start">
    <div fxFlex="50" fxLayoutAlign="start start">
        <p class="title">Objet:</p><p class="titleBody">{{src.objet}}</p>
    </div>
    <div fxFlex="50" fxLayoutAlign="end end">
        <p class="title">Echeance:</p><p class="titleBody">{{src.echeance | date:'shortDate'}}</p>
    </div>
</div>
<div fxlayout="row wrap" fxLayoutAlign="start start">
    <div fxFlex="50" fxLayoutAlign="start start">
        <p class="title">Instructions:</p><p class="titleBody">{{src.autres}}</p>
    </div>
</div>
<div fxlayout="row wrap" fxLayoutAlign="start start">
    <div fxFlex="50" fxLayoutAlign="start start">
        <p class="title">Observations:</p><p class="titleBody">{{src.niveau}}</p>
    </div>
</div>
<div fxlayout="row wrap" fxLayoutAlign="center center">
    <div fxFlex="20" fxLayoutAlign="start start">
        <p class="title">Services imputés:</p>
    </div>
</div>
<div fxlayout="row wrap" fxLayoutAlign="start start">
    <div fxFlex="100" style="border: 1px solid #41B3A3 " fxLayoutAlign="start start">
        <mat-chip-list class="chips">
            <mat-chip class="chips" *ngIf="src.accueilStages">Accueil et stages</mat-chip>
            <mat-chip class="chips" *ngIf="src.assistantDirection">Assistante de direction</mat-chip>
            <mat-chip class="chips" *ngIf="src.entretientMaintenance">Entretient et maintenance</mat-chip>
            <mat-chip class="chips" *ngIf="src.etudesInnovationsPedagogiques">Etudes et innovations pedagogiques</mat-chip>
            <mat-chip class="chips" *ngIf="src.intendance">Intendance</mat-chip>
            <mat-chip class="chips" *ngIf="src.programmeUtilisationTerrainsGazonnes">Programme utilisation des pelouses</mat-chip>
            <mat-chip class="chips" *ngIf="src.surveillantGeneral">Surveillant General</mat-chip>
        </mat-chip-list>
    </div>
</div>
<form [formGroup]="imputerForm" (ngSubmit)="onSubmit()" class="normal-form">
    <div fxLayout="row">
        <div fxFlex="100">
            <mat-grid-list cols="1" rowHeight="300px">
                <mat-grid-tile>
                    <div class="controles-container">
                        <h2>Observations:</h2>
                        <mat-form-field>
                            <input [disabled]="src.attribution" formControlName="niveau" matInput placeholder="observations" >
                            <mat-error>Ce champ est requis !</mat-error>
                        </mat-form-field>
                    </div>
                </mat-grid-tile>
            </mat-grid-list>
        </div>
    </div>
    <button style="position: relative ; float: right" [disabled]="src.attribution" mat-raised-button color="primary" type="submit">Valider</button>
</form>
