import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {PdfViewComponent} from '../../EtudesEtInnovations/pdf-view/pdf-view.component';
import {MediaService} from '../../../services/media.service';
import {ViewdetailsImputeComponent} from '../../Directeur/viewdetails-impute/viewdetails-impute.component';
import {ViewdetailsDepartImputeComponent} from '../../Directeur/viewdetails-depart-impute/viewdetails-depart-impute.component';
import {PdfViewSecretaireComponent} from '../../Secretaire/pdf-view-secretaire/pdf-view-secretaire.component';

@Component({
  selector: 'app-home-media',
  templateUrl: './home-media.component.html',
  styleUrls: ['./home-media.component.scss']
})
export class HomeMediaComponent implements OnInit {
  arriveesData: any ;
  departsData: any ;
  columnsToDisplay = ['dateArrivee', 'dateCorrespondance', 'numeroCorrespondance', 'expediteur' , 'objet' , 'imputation'];
  columnsToDisplayPrime = ['dateDeDepart', 'destinataire', 'numeroCorrespondance', 'numeroArchive' , 'objet', 'imputation'];

  constructor(
      private dialog: MatDialog ,
      private mediaService: MediaService,
  ) { }

  ngOnInit(): void {
    this.loadAllArrivees();
    this.loadAllDeparts() ;
  }
  // tslint:disable-next-line:typedef
  ViewDetails(src: any) {
    const dialogConfig = new MatDialogConfig() ;
    dialogConfig.disableClose = false ;
    dialogConfig.autoFocus = true ;
    dialogConfig.width = '90%';
    dialogConfig.height = '800px' ;
    dialogConfig.data = src ;
    dialogConfig.id = src ;
    this.dialog.open(ViewdetailsImputeComponent , dialogConfig) ;
    this.dialog.afterAllClosed.subscribe(() => {

    } );
  }
  // tslint:disable-next-line:typedef
  ViewDetailsDepart(src: any) {
    const dialogConfig = new MatDialogConfig() ;
    dialogConfig.disableClose = false ;
    dialogConfig.autoFocus = true ;
    dialogConfig.width = '90%';
    dialogConfig.height = '800px' ;
    dialogConfig.data = src ;
    dialogConfig.id = src ;
    this.dialog.open(ViewdetailsDepartImputeComponent , dialogConfig) ;
    this.dialog.afterAllClosed.subscribe(() => {
    } );
  }
  // tslint:disable-next-line:typedef
  onNavigate(){
    const url = 'https://cneps-thies.odoo.com';
    window.open(url, '_blank');
  }
  // tslint:disable-next-line:typedef
  loadAllArrivees() {
    // @ts-ignore
    this.mediaService.getAllImputesArrivees()
        .subscribe(
            data => {this.arriveesData = data , console.log(this.arriveesData);  }  );
  }
  // tslint:disable-next-line:typedef
  loadAllDeparts() {
    // @ts-ignore
    this.mediaService.getAllImputesDepart()
        .subscribe(
            data => {this.departsData = data , console.log(this.departsData);  }  );
  }
  // tslint:disable-next-line:typedef
  ViewPdf(src: any) {
    const dialogConfig = new MatDialogConfig() ;
    dialogConfig.disableClose = false ;
    dialogConfig.autoFocus = true ;
    dialogConfig.width = '90%';
    dialogConfig.height = '800px' ;
    dialogConfig.data = src ;
    dialogConfig.id = src ;
    this.dialog.open(PdfViewSecretaireComponent , dialogConfig) ;
    this.dialog.afterAllClosed.subscribe(() => {

    } );
  }

}
