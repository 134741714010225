import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DirecteurService {
  private API_URL = environment.apiUrl;

  constructor(
    private http: HttpClient,
  ) { }

  // tslint:disable-next-line:typedef
  imputeCourrierArrivee(id: any, data: any) {
    console.log(data);
    return this.http.put<any>( `${ this.API_URL }/courrier_arrivees/${id}`, data)
      .pipe ( map (reponse => {
        console.log(reponse);
        return reponse;
      })) ;
  }
    // tslint:disable-next-line:typedef
    deleteA(id: any) {
        return this.http.delete<any>( `${ this.API_URL }/courrier_arrivees/${id}`)
            .pipe ( map (reponse => {
                console.log(reponse);
                return reponse;
            })) ;
    }
    // tslint:disable-next-line:typedef
    deleteD(id: any) {
        return this.http.delete<any>( `${ this.API_URL }/courrier_departs/${id}`)
            .pipe ( map (reponse => {
                console.log(reponse);
                return reponse;
            })) ;
    }
    // tslint:disable-next-line:typedef
    deleteO(id: any) {
        return this.http.delete<any>( `${ this.API_URL }/ordre_missions/${id}`)
            .pipe ( map (reponse => {
                console.log(reponse);
                return reponse;
            })) ;
    }
  // tslint:disable-next-line:typedef
  imputeCourrierDepart(id: any, data: any) {
    console.log(data);
    return this.http.put<any>( `${ this.API_URL }/courrier_departs/${id}`, data)
        .pipe ( map (reponse => {
          console.log(reponse);
          return reponse;
        })) ;
  }
// tslint:disable-next-line:typedef
    archiveAudience(id: any, data: any) {
        console.log(data);
        return this.http.put<any>( `${ this.API_URL }/directeur/audiencesPut/${id}`, data)
            .pipe ( map (reponse => {
                console.log(reponse);
                return reponse;
            })) ;
    }
    // tslint:disable-next-line:typedef
    archiveOrdre(id: any, data: any) {
        console.log(data);
        return this.http.put<any>( `${ this.API_URL }/ordre_missions/${id}`, data)
            .pipe ( map (reponse => {
                console.log(reponse);
                return reponse;
            })) ;
    }
}
