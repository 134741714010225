import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {DirecteurService} from '../../../services/directeur.service';

@Component({
  selector: 'app-viewdetails-depart-impute',
  templateUrl: './viewdetails-depart-impute.component.html',
  styleUrls: ['./viewdetails-depart-impute.component.scss']
})
export class ViewdetailsDepartImputeComponent implements OnInit {
  src: any ;
  imputerForm: FormGroup;

  constructor(
      public dialogRef: MatDialogRef<ViewdetailsDepartImputeComponent>,
      private directeurService: DirecteurService ,
  ) { }

  ngOnInit(): void {
    this.src = this.dialogRef.id ;
    console.log(this.src) ;
    this.imputerForm = new FormGroup(
        {
          niveau: new FormControl(null),
        });
  }
// tslint:disable-next-line:typedef
  get f() { return this.imputerForm.controls; }

  // tslint:disable-next-line:typedef
  onSubmit() {
    // alert(this.dialogRef.id) ;
    const formData = new FormData();
    console.log(formData);
    //   this.submitted = true;
// reset alerts on submit
    // @ts-ignore
    // this.alertService.clear();
    // stop here if form is invalid
    this.directeurService.imputeCourrierDepart( this.src.id , this.imputerForm.value)
        .subscribe(
            user => {
              this.imputerForm.reset() ;
              // window.location.reload() ;
              this.onClose() ;
            });
  }
  // tslint:disable-next-line:typedef
  onClose() {
    this.dialogRef.close() ;
  }
}
