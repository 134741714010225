
<div fxlayout="row wrap" fxLayoutAlign="start start">
    <div fxFlex="100">
        <div fxlayout="row" fxLayoutAlign="center center">
            <h2>Details de l'audience</h2>
        </div>
    </div>
</div>
<div fxlayout="row wrap" fxLayoutAlign="start start">
    <div fxFlex="50" fxLayoutAlign="start start">
        <p class="title">Demandeur:</p><p class="titleBody">{{src.demandeur}}</p>
    </div>
</div>
<div fxlayout="row wrap" fxLayoutAlign="center center">
    <div fxFlex="20" fxLayoutAlign="start start">
        <p class="title">Observations:</p>
    </div>
</div>
<div fxlayout="row wrap" fxLayoutAlign="start start">
    <div fxFlex="100" style="border: 1px solid #41B3A3 " fxLayoutAlign="start start">
        <p class="titleBody">{{src.observations}}</p>
    </div>
</div>
<form [formGroup]="archiveAudienceForm" (ngSubmit)="archiverAudience(src.id)" class="normal-form">
    <div fxLayout="row">
        <div fxFlex="100">
            <mat-grid-list cols="1" rowHeight="300px">
                <mat-grid-tile>
                    <div class="controles-container">
                        <h2>Observations:</h2>
                        <mat-form-field>
                            <input [disabled]="src.archive" formControlName="observations" matInput placeholder="observations" >
                            <mat-error>Ce champ est requis !</mat-error>
                        </mat-form-field>
                    </div>
                </mat-grid-tile>
            </mat-grid-list>
        </div>
    </div>
    <button style="position: relative ; float: right" [disabled]="src.archive" mat-raised-button color="primary" type="submit">Valider</button>
</form>