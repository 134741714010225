import { Component, OnInit } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {SecretaireService} from '../../../services/secretaire.service';
import {first, map, pluck} from 'rxjs/operators';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {CourrierArriveeComponent} from '../../Secretaire/courrier-arrivee/courrier-arrivee.component';
import {CourrierDepartComponent} from '../../Secretaire/courrier-depart/courrier-depart.component';
import {AudiencesComponent} from '../../Secretaire/audiences/audiences.component';
import {ImputerComponent} from '../imputer/imputer.component';
import {PdfViewSecretaireComponent} from '../../Secretaire/pdf-view-secretaire/pdf-view-secretaire.component';
import {ImputeDepartComponent} from '../impute-depart/impute-depart.component';
import {ViewdetailsImputeComponent} from '../viewdetails-impute/viewdetails-impute.component';
import {FormControl, FormGroup} from '@angular/forms';
import {DirecteurService} from '../../../services/directeur.service';
import {DetailsAudienceComponent} from '../details-audience/details-audience.component';
import {OrdreComponent} from '../../Secretaire/ordre/ordre.component';
import {ViewdetailsDepartImputeComponent} from '../viewdetails-depart-impute/viewdetails-depart-impute.component';
import {PdfDepartComponent} from '../../Secretaire/pdf-depart/pdf-depart.component';

@Component({
  selector: 'app-home-director',
  templateUrl: './home-director.component.html',
  styleUrls: ['./home-director.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('detailExpandPrime', [
      state('collapsedPrime', style({height: '0px', minHeight: '0'})),
      state('expandedPrime', style({height: '*'})),
      transition('expandedPrime <=> collapsedPrime', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class HomeDirectorComponent implements OnInit {
    archiveForm: FormGroup;
    archiveAudienceForm: FormGroup;
    archiveOrdreForm: FormGroup;
  OneCompet: any ;
    cArrivee: any ;
  Janvier: any  ;
  Fevrier: any ;
  Mars: any  ;
  Avril: any ;
  Mai: any = [] ;
  Juin: any ;
  Juillet: any ;
  Aout: any ;
  Septembre: any ;
  Octobre: any ;
  Novembre: any ;
  Decembre: any ;
    JanvierArchive: any  ;
    FevrierArchive: any ;
    MarsArchive: any  ;
    AvrilArchive: any ;
    MaiArchive: any = [] ;
    JuinArchive: any ;
    JuilletArchive: any ;
    AoutArchive: any ;
    SeptembreArchive: any ;
    OctobreArchive: any ;
    NovembreArchive: any ;
    DecembreArchive: any ;
  arriveesData: any ;
    arriveesDataImputes: any ;
    arriveesDataImputesArchives: any ;
    selected: any = []  ;
    ordreData: any ;
    ordreDataArchive: any ;
    departsData: any ;
    departsDataImputes: any ;
    departsDataImputesArchives: any ;
    agendasData: any ;
  agenda: any ;
  dataSource: any;
  dataSource2: any;
  columnsToDisplay = ['dateArrivee', 'dateCorrespondance', 'numeroCorrespondance', 'expediteur' , 'objet' , 'imputation'];
  columnsToDisplayPrime = ['dateDeDepart', 'destinataire', 'numeroCorrespondance', 'numeroArchive' , 'objet', 'imputation'];
    columnsToDisplaySecond = ['dateArrivee', 'echeance', 'objet', 'expediteur' , 'actions'];
    columnsToDisplayTroisieme = ['dateDeDepart', 'echeance', 'objet', 'destinataire' , 'actions'];
    displayedColumnsSecond = ['Jour', 'Debut', 'Fin', 'Organisme', 'Observation'];
    displayedColumnsTroisieme = ['Nom', 'Fonction', 'Date', 'Lieu', 'Intitule' , 'Description' , 'actions'];
    dataSource3: any;
  expandedElement: any | null;
  expandedElementPrime: any | null;
  mois: any [] ;
  id: any ;

  constructor(
    private secretaireService: SecretaireService ,
    private directeurService: DirecteurService,
    private dialog: MatDialog
  ) {
    this.mois = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout' , 'Septembre', 'Octobre', 'Novembre', 'Decembre'];
  }

  ngOnInit(): void {
    this.loadJanvier() ;
    this.loadFevrier() ;
    this.loadMars() ;
    this.loadAvril() ;
    this.loadMai() ;
    this.loadJuin() ;
    this.loadJuillet() ;
    this.loadAout() ;
    this.loadSeptembre() ;
    this.loadOctobre() ;
    this.loadNovembre() ;
    this.loadDecembre() ;
    this.loadJanvierArchive() ;
    this.loadFevrierArchive() ;
    this.loadMarsArchive() ;
    this.loadAvrilArchive() ;
    this.loadMaiArchive() ;
    this.loadJuinArchive() ;
    this.loadJuilletArchive() ;
    this.loadAoutArchive() ;
    this.loadSeptembreArchive() ;
    this.loadOctobreArchive() ;
    this.loadNovembreArchive() ;
    this.loadDecembreArchive() ;
    this.loadAllArrivees() ;
    this.loadAllDeparts() ;
    this.loadAllArriveesImputes() ;
    this.loadAllDepartsImputes() ;
    this.loadAllArriveesImputesArchives();
    this.loadAllDepartsImputesArchives() ;
    this.loadAllOrdres() ;
    this.loadAllOrdresArchive();
    console.log(this.selected) ;
    this.agenda = this.agendasData ;
    this.archiveForm = new FormGroup(
          {
              attribution: new FormControl('true'),
          });
    this.archiveAudienceForm = new FormGroup(
          {
              archive: new FormControl(true),
          });
    this.archiveOrdreForm = new FormGroup(
          {
              archive: new FormControl(true),
          });
  }
    // tslint:disable-next-line:typedef
    deleteArrivee(id) {
        this.directeurService.deleteA(id)
            .subscribe(
                user => {
                    this.loadAllArrivees() ;
                    this.loadAllArriveesImputesArchives() ;
                });
    }
    // tslint:disable-next-line:typedef
    deleteDepart(id) {
        this.directeurService.deleteD(id)
            .subscribe(
                user => {
                    this.loadAllDeparts() ;
                    this.loadAllDepartsImputes() ;
                    this.loadAllDepartsImputesArchives() ;
                });
    }
    // tslint:disable-next-line:typedef
    deleteOrdre(id) {
        this.directeurService.deleteO(id)
            .subscribe(
                user => {
                    this.loadAllOrdres() ;
                    this.loadAllOrdresArchive() ;
                });
    }
    // tslint:disable-next-line:typedef
    createArrive() {
        const dialogConfig = new MatDialogConfig() ;
        dialogConfig.disableClose = false ;
        dialogConfig.autoFocus = true ;
        dialogConfig.width = '60%';
        this.dialog.open(CourrierArriveeComponent , dialogConfig) ;
        this.dialog.afterAllClosed.subscribe(() => { this.loadAllArrivees(); } );
    }
    // tslint:disable-next-line:typedef
    createDepart() {
        const dialogConfig = new MatDialogConfig() ;
        dialogConfig.disableClose = false ;
        dialogConfig.autoFocus = true ;
        dialogConfig.width = '60%';
        this.dialog.open(CourrierDepartComponent , dialogConfig) ;
        this.dialog.afterAllClosed.subscribe(() => { this.loadAllDeparts(); } );
    }
    // tslint:disable-next-line:typedef
    createOrdre() {
        const dialogConfig = new MatDialogConfig() ;
        dialogConfig.disableClose = false ;
        dialogConfig.autoFocus = true ;
        dialogConfig.width = '60%';
        this.dialog.open(OrdreComponent , dialogConfig) ;
        this.dialog.afterAllClosed.subscribe(() => {
            this.loadAllOrdres();
            this.loadAllOrdresArchive(); } );
    }
    // tslint:disable-next-line:typedef
    AjoutAudience() {
        const dialogConfig = new MatDialogConfig() ;
        dialogConfig.disableClose = false ;
        dialogConfig.autoFocus = true ;
        dialogConfig.width = '60%';
        this.dialog.open(AudiencesComponent , dialogConfig) ;
        this.dialog.afterAllClosed.subscribe(() => {
            this.loadJanvier() ;
            this.loadFevrier() ;
            this.loadMars() ;
            this.loadAvril() ;
            this.loadMai() ;
            this.loadJuin() ;
            this.loadJuillet() ;
            this.loadAout() ;
            this.loadSeptembre() ;
            this.loadOctobre() ;
            this.loadNovembre() ;
            this.loadDecembre() ;
        } );
    }
    // tslint:disable-next-line:typedef
    loadAllOrdres() {
        // @ts-ignore
        this.secretaireService.getAllOrdres()
            .pipe(map(x => JSON.stringify(x)),
                map(x => JSON.parse(x)),
                pluck('hydra:member'))
            .subscribe(
                data => {
                    this.ordreData = data ;
                       // console.log(this.ordreData);
                }  );
    }
    // tslint:disable-next-line:typedef
    loadAllOrdresArchive() {
        // @ts-ignore
        this.secretaireService.getAllOrdresArchive()
            .pipe(map(x => JSON.stringify(x)),
                map(x => JSON.parse(x)),
                pluck('hydra:member'))
            .subscribe(
                data => {this.ordreDataArchive = data ; /* console.log(this.ordreDataArchive); */  }  );
    }
    // tslint:disable-next-line:typedef
    get f() { return this.archiveForm.controls; }

    // tslint:disable-next-line:typedef
    archiver(id: any) {
      console.log(id) ;
      console.log(this.archiveForm.value) ;
        // alert(this.dialogRef.id) ;
        //   this.submitted = true;
// reset alerts on submit
        // @ts-ignore
        // this.alertService.clear();
        // stop here if form is invalid
      this.directeurService.imputeCourrierArrivee( id , this.archiveForm.value)
            .subscribe(
                user => {
                    this.loadAllArriveesImputes() ;
                    this.loadAllDepartsImputes() ;
                    this.loadAllArriveesImputesArchives();
                    this.loadAllDepartsImputesArchives();
                    // window.location.reload() ;
                });
    }
    // tslint:disable-next-line:typedef
    archiverDepart(id: any) {
        console.log(id) ;
        console.log(this.archiveForm.value) ;
        // alert(this.dialogRef.id) ;
        //   this.submitted = true;
// reset alerts on submit
        // @ts-ignore
        // this.alertService.clear();
        // stop here if form is invalid
        this.directeurService.imputeCourrierDepart( id , this.archiveForm.value)
            .subscribe(
                user => {
                    this.loadAllArriveesImputes() ;
                    this.loadAllDepartsImputes() ;
                    // window.location.reload() ;
                });
    }
    // tslint:disable-next-line:typedef
    archiverAudience(id: any) {
        console.log(id) ;
      //  console.log(this.archiveForm.value) ;
        // alert(this.dialogRef.id) ;
        //   this.submitted = true;
// reset alerts on submit
        // @ts-ignore
        // this.alertService.clear();
        // stop here if form is invalid
        this.directeurService.archiveAudience( id , this.archiveAudienceForm.value)
            .subscribe(
                user => {
                    this.loadJanvier() ;
                    this.loadFevrier() ;
                    this.loadMars() ;
                    this.loadAvril() ;
                    this.loadMai() ;
                    this.loadJuin() ;
                    this.loadJuillet() ;
                    this.loadAout() ;
                    this.loadSeptembre() ;
                    this.loadOctobre() ;
                    this.loadNovembre() ;
                    this.loadDecembre() ;
                    this.loadJanvierArchive() ;
                    this.loadFevrierArchive() ;
                    this.loadMarsArchive() ;
                    this.loadAvrilArchive() ;
                    this.loadMaiArchive() ;
                    this.loadJuinArchive() ;
                    this.loadJuilletArchive() ;
                    this.loadAoutArchive() ;
                    this.loadSeptembreArchive() ;
                    this.loadOctobreArchive() ;
                    this.loadNovembreArchive() ;
                    this.loadDecembreArchive() ;
                    // window.location.reload() ;
                });
    }
    // tslint:disable-next-line:typedef
    archiverOrdre(id: any) {
        console.log(id) ;
        //  console.log(this.archiveForm.value) ;
        // alert(this.dialogRef.id) ;
        //   this.submitted = true;
// reset alerts on submit
        // @ts-ignore
        // this.alertService.clear();
        // stop here if form is invalid
        this.directeurService.archiveOrdre( id , this.archiveOrdreForm.value)
            .subscribe(
                user => {
                    this.loadAllOrdres() ;
                    this.loadAllOrdresArchive();
                    // window.location.reload() ;
                });
    }
  // tslint:disable-next-line:typedef
  loadAllArrivees() {
    // @ts-ignore
    this.secretaireService.getAll()
      .subscribe(
        data => {this.arriveesData = data , this.dataSource = data ; /* console.log(this.arriveesData); */  }  );
  }
  // tslint:disable-next-line:typedef
  loadAllDeparts() {
    // @ts-ignore
    this.secretaireService.getAllDeparts()
      .subscribe(
        data => {this.departsData = data , this.dataSource2 = data ; /* console.log(this.dataSource2); */   }  );
  }
    // tslint:disable-next-line:typedef
    loadAllArriveesImputes() {
        // @ts-ignore
        this.secretaireService.getAllArrivesImputes()
            .subscribe(
                data => {this.arriveesDataImputes = data ; /* console.log(this.arriveesDataImputes);  */  }  );
    }
    // tslint:disable-next-line:typedef
    loadAllDepartsImputes() {
        // @ts-ignore
        this.secretaireService.getAllDepartsImputes()
            .subscribe(
                data => {this.departsDataImputes = data ; /*  console.log(this.departsDataImputes); */ }  );
    }
    // tslint:disable-next-line:typedef
    loadAllArriveesImputesArchives() {
        // @ts-ignore
        this.secretaireService.getAllArrivesImputesArchive()
            .subscribe(
                data => {this.arriveesDataImputesArchives = data ; /* console.log(this.arriveesDataImputes); */  }  );
    }
    // tslint:disable-next-line:typedef
    loadAllDepartsImputesArchives() {
        // @ts-ignore
        this.secretaireService.getAllDepartsImputesArchive()
            .subscribe(
                data => {this.departsDataImputesArchives = data ; /* console.log(this.departsDataImputes); */  }  );
    }
    // tslint:disable-next-line:typedef
        ViewPdf(src: any) {
        console.log(src) ;
        const dialogConfig = new MatDialogConfig() ;
        dialogConfig.disableClose = false ;
        dialogConfig.autoFocus = true ;
        dialogConfig.width = '90%';
        dialogConfig.height = '800px' ;
        dialogConfig.data = src ;
        dialogConfig.id = src ;
        this.dialog.open(PdfViewSecretaireComponent , dialogConfig) ;
        this.dialog.afterAllClosed.subscribe(() => {

        } );
    }
    // tslint:disable-next-line:typedef
    ViewPdfDepart(src: any) {
        console.log(src) ;
        const dialogConfig = new MatDialogConfig() ;
        dialogConfig.disableClose = false ;
        dialogConfig.autoFocus = true ;
        dialogConfig.width = '90%';
        dialogConfig.height = '800px' ;
        dialogConfig.data = src ;
        dialogConfig.id = src ;
        this.dialog.open(PdfDepartComponent , dialogConfig) ;
        this.dialog.afterAllClosed.subscribe(() => {

        } );
    }
    // tslint:disable-next-line:typedef
    ViewDetails(src: any) {
        const dialogConfig = new MatDialogConfig() ;
        dialogConfig.disableClose = false ;
        dialogConfig.autoFocus = true ;
        dialogConfig.width = '90%';
        dialogConfig.height = '800px' ;
        dialogConfig.data = src ;
        dialogConfig.id = src ;
        this.dialog.open(ViewdetailsImputeComponent , dialogConfig) ;
        this.dialog.afterAllClosed.subscribe(() => {
            this.loadAllArriveesImputes() ;
            this.loadAllDepartsImputes() ;

        } );
    }
    // tslint:disable-next-line:typedef
    ViewDetailsDepart(src: any) {
        const dialogConfig = new MatDialogConfig() ;
        dialogConfig.disableClose = false ;
        dialogConfig.autoFocus = true ;
        dialogConfig.width = '90%';
        dialogConfig.height = '800px' ;
        dialogConfig.data = src ;
        dialogConfig.id = src ;
        this.dialog.open(ViewdetailsDepartImputeComponent , dialogConfig) ;
        this.dialog.afterAllClosed.subscribe(() => {
            this.loadAllArriveesImputes() ;
            this.loadAllDepartsImputes() ;

        } );
    }
    // tslint:disable-next-line:typedef
    ViewDetailsAudience(src: any) {
        const dialogConfig = new MatDialogConfig() ;
        dialogConfig.disableClose = false ;
        dialogConfig.autoFocus = true ;
        dialogConfig.width = '90%';
        dialogConfig.height = '800px' ;
        dialogConfig.data = src ;
        dialogConfig.id = src ;
        this.dialog.open(DetailsAudienceComponent , dialogConfig) ;
        this.dialog.afterAllClosed.subscribe(() => {
            this.loadJanvier() ;
            this.loadFevrier() ;
            this.loadMars() ;
            this.loadAvril() ;
            this.loadMai() ;
            this.loadJuin() ;
            this.loadJuillet() ;
            this.loadAout() ;
            this.loadSeptembre() ;
            this.loadOctobre() ;
            this.loadNovembre() ;
            this.loadDecembre() ;
            this.loadJanvierArchive() ;
            this.loadFevrierArchive() ;
            this.loadMarsArchive() ;
            this.loadAvrilArchive() ;
            this.loadMaiArchive() ;
            this.loadJuinArchive() ;
            this.loadJuilletArchive() ;
            this.loadAoutArchive() ;
            this.loadSeptembreArchive() ;
            this.loadOctobreArchive() ;
            this.loadNovembreArchive() ;
            this.loadDecembreArchive() ;
        } );
    }
  /* // tslint:disable-next-line:typedef
   loadAllAgendas() {
     // @ts-ignore
     this.secretaireService.getAllAgenda()
       .pipe(map(x => JSON.stringify(x)),
         map(x => JSON.parse(x)),
         pluck('hydra:member'))
       .subscribe(
         data => {this.agendasData = data  ,
           console.log(this.agendasData) ;
           this.dataSource3 = this.agendasData
            }  );
     return this.agendasData ;
   }*/
  // tslint:disable-next-line:typedef
  loadJanvier() {
    this.secretaireService.getJanvier()
      .pipe(first())
      .subscribe(agenda => {
        this.Janvier = agenda ;
        console.log(this.Janvier) ;
        this.selected.push(this.Janvier) ;
      } ) ;
    //  return [this.grpCompet , this.compets , this.selected] ;
  }
  // tslint:disable-next-line:typedef
  loadFevrier() {
    this.secretaireService.getFevrier()
      .pipe(first())
      .subscribe(agenda => {
        this.Fevrier = agenda ;
        console.log(this.Fevrier) ;
        this.selected.push(this.Fevrier) ;
      } ) ;
    //  return [this.grpCompet , this.compets , this.selected] ;
  }
  // tslint:disable-next-line:typedef
  loadMars() {
    this.secretaireService.getMars()
      .pipe(first())
      .subscribe(agenda => {
        this.Mars = agenda ;
        console.log(this.Mars) ;
        this.selected.push(this.Mars) ;
      } ) ;
    //  return [this.grpCompet , this.compets , this.selected] ;
  }
  // tslint:disable-next-line:typedef
  loadAvril() {
    this.secretaireService.getAvril()
      .pipe(first())
      .subscribe(agenda => {
        this.Avril = agenda ;
        console.log(this.Avril) ;
        this.selected.push(this.Avril) ;
      } ) ;
    //  return [this.grpCompet , this.compets , this.selected] ;
  }
  // tslint:disable-next-line:typedef
  loadMai() {
    this.secretaireService.getMai()
      .pipe(first())
      .subscribe(agenda => {
        console.log(agenda) ;
        this.Mai = agenda ;
        console.log(this.Mai) ;
        this.selected.push(this.Mai) ;
      } ) ;
    //  return [this.grpCompet , this.compets , this.selected] ;
  }
  // tslint:disable-next-line:typedef
  loadJuin() {
    this.secretaireService.getJuin()
      .pipe(first())
      .subscribe(agenda => {
        this.Juin = agenda ;
        console.log(this.Juin) ;
        this.selected.push(this.Juin) ;
      } ) ;
    //  return [this.grpCompet , this.compets , this.selected] ;
  }
  // tslint:disable-next-line:typedef
  loadJuillet() {
    this.secretaireService.getJuillet()
      .pipe(first())
      .subscribe(agenda => {
        this.Juillet = agenda ;
        console.log(this.Juillet) ;
        this.selected.push(this.Juillet) ;
      } ) ;
    //  return [this.grpCompet , this.compets , this.selected] ;
  }
  // tslint:disable-next-line:typedef
  loadAout() {
    this.secretaireService.getAout()
      .pipe(first())
      .subscribe(agenda => {
        this.Aout = agenda ;
        console.log(this.Aout) ;
        this.selected.push(this.Aout) ;
      } ) ;
    //  return [this.grpCompet , this.compets , this.selected] ;
  }
  // tslint:disable-next-line:typedef
  loadSeptembre() {
    this.secretaireService.getSeptembre()
      .pipe(first())
      .subscribe(agenda => {
        this.Septembre = agenda ;
        console.log(this.Septembre) ;
        this.selected.push(this.Septembre) ;
      } ) ;
    //  return [this.grpCompet , this.compets , this.selected] ;
  }
  // tslint:disable-next-line:typedef
  loadOctobre() {
    this.secretaireService.getOctobre()
      .pipe(first())
      .subscribe(agenda => {
        this.Octobre = agenda ;
        console.log(this.Octobre) ;
        this.selected.push(this.Octobre) ;
      } ) ;
    //  return [this.grpCompet , this.compets , this.selected] ;
  }
  // tslint:disable-next-line:typedef
  loadNovembre() {
    this.secretaireService.getNovembre()
      .pipe(first())
      .subscribe(agenda => {
        this.Novembre = agenda ;
        console.log(this.Novembre) ;
        this.selected.push(this.Novembre) ;
      } ) ;
    //  return [this.grpCompet , this.compets , this.selected] ;
  }
  // tslint:disable-next-line:typedef
  loadDecembre() {
    this.secretaireService.getDecembre()
      .pipe(first())
      .subscribe(agenda => {
        this.Decembre = agenda ;
        console.log(this.Decembre) ;
        this.selected.push(this.Decembre) ;
      } ) ;
    //  return [this.grpCompet , this.compets , this.selected] ;
  }
    // tslint:disable-next-line:typedef
    loadJanvierArchive() {
        this.secretaireService.getJanvierArchive()
            .pipe(first())
            .subscribe(agenda => {
                this.JanvierArchive = agenda ;
                console.log(this.JanvierArchive) ;
                this.selected.push(this.Janvier) ;
            } ) ;
        //  return [this.grpCompet , this.compets , this.selected] ;
    }
    // tslint:disable-next-line:typedef
    loadFevrierArchive() {
        this.secretaireService.getFevrierArchive()
            .pipe(first())
            .subscribe(agenda => {
                this.FevrierArchive = agenda ;
                console.log(this.FevrierArchive) ;
                this.selected.push(this.Fevrier) ;
            } ) ;
        //  return [this.grpCompet , this.compets , this.selected] ;
    }
    // tslint:disable-next-line:typedef
    loadMarsArchive() {
        this.secretaireService.getMarsArchive()
            .pipe(first())
            .subscribe(agenda => {
                this.MarsArchive = agenda ;
                console.log(this.MarsArchive) ;
                this.selected.push(this.Mars) ;
            } ) ;
        //  return [this.grpCompet , this.compets , this.selected] ;
    }
    // tslint:disable-next-line:typedef
    loadAvrilArchive() {
        this.secretaireService.getAvrilArchive()
            .pipe(first())
            .subscribe(agenda => {
                this.AvrilArchive = agenda ;
                console.log(this.AvrilArchive) ;
                this.selected.push(this.Avril) ;
            } ) ;
        //  return [this.grpCompet , this.compets , this.selected] ;
    }
    // tslint:disable-next-line:typedef
    loadMaiArchive() {
        this.secretaireService.getMaiArchive()
            .pipe(first())
            .subscribe(agenda => {
                console.log(agenda) ;
                this.MaiArchive = agenda ;
                console.log(this.MaiArchive) ;
                this.selected.push(this.Mai) ;
            } ) ;
        //  return [this.grpCompet , this.compets , this.selected] ;
    }
    // tslint:disable-next-line:typedef
    loadJuinArchive() {
        this.secretaireService.getJuinArchive()
            .pipe(first())
            .subscribe(agenda => {
                this.JuinArchive = agenda ;
                console.log(this.JuinArchive) ;
                this.selected.push(this.Juin) ;
            } ) ;
        //  return [this.grpCompet , this.compets , this.selected] ;
    }
    // tslint:disable-next-line:typedef
    loadJuilletArchive() {
        this.secretaireService.getJuilletArchive()
            .pipe(first())
            .subscribe(agenda => {
                this.JuilletArchive = agenda ;
                console.log(this.JuilletArchive) ;
                this.selected.push(this.Juillet) ;
            } ) ;
        //  return [this.grpCompet , this.compets , this.selected] ;
    }
    // tslint:disable-next-line:typedef
    loadAoutArchive() {
        this.secretaireService.getAoutArchive()
            .pipe(first())
            .subscribe(agenda => {
                this.AoutArchive = agenda ;
                console.log(this.AoutArchive) ;
                this.selected.push(this.Aout) ;
            } ) ;
        //  return [this.grpCompet , this.compets , this.selected] ;
    }
    // tslint:disable-next-line:typedef
    loadSeptembreArchive() {
        this.secretaireService.getSeptembreArchive()
            .pipe(first())
            .subscribe(agenda => {
                this.SeptembreArchive = agenda ;
                console.log(this.SeptembreArchive) ;
                this.selected.push(this.Septembre) ;
            } ) ;
        //  return [this.grpCompet , this.compets , this.selected] ;
    }
    // tslint:disable-next-line:typedef
    loadOctobreArchive() {
        this.secretaireService.getOctobreArchive()
            .pipe(first())
            .subscribe(agenda => {
                this.OctobreArchive = agenda ;
                console.log(this.OctobreArchive) ;
                this.selected.push(this.Octobre) ;
            } ) ;
        //  return [this.grpCompet , this.compets , this.selected] ;
    }
    // tslint:disable-next-line:typedef
    loadNovembreArchive() {
        this.secretaireService.getNovembreArchive()
            .pipe(first())
            .subscribe(agenda => {
                this.NovembreArchive = agenda ;
                console.log(this.NovembreArchive) ;
                this.selected.push(this.Novembre) ;
            } ) ;
        //  return [this.grpCompet , this.compets , this.selected] ;
    }
    // tslint:disable-next-line:typedef
    loadDecembreArchive() {
        this.secretaireService.getDecembreArchive()
            .pipe(first())
            .subscribe(agenda => {
                this.DecembreArchive = agenda ;
                console.log(this.DecembreArchive) ;
                this.selected.push(this.Decembre) ;
            } ) ;
        //  return [this.grpCompet , this.compets , this.selected] ;
    }
    // tslint:disable-next-line:typedef
  imputer(idd: any) {
    const dialogConfig = new MatDialogConfig() ;
    dialogConfig.disableClose = false ;
    dialogConfig.autoFocus = true ;
    dialogConfig.width = '60%';
    dialogConfig.data = idd ;
    dialogConfig.id = idd ;
    this.dialog.open(ImputerComponent , dialogConfig) ;
    this.dialog.afterAllClosed.subscribe(() => {
          this.loadAllArrivees() ;
          this.loadAllDeparts() ;
      }) ;
  }
    // tslint:disable-next-line:typedef
    imputerDepart(idd: any) {
        const dialogConfig = new MatDialogConfig() ;
        dialogConfig.disableClose = false ;
        dialogConfig.autoFocus = true ;
        dialogConfig.width = '60%';
        dialogConfig.data = idd ;
        dialogConfig.id = idd ;
        this.dialog.open(ImputeDepartComponent , dialogConfig) ;
        this.dialog.afterAllClosed.subscribe(() => {
            this.loadAllArrivees() ;
            this.loadAllDeparts() ;
        }) ;
    }
}
