<div fxlayout="row wrap" fxLayoutAlign="center center">
  <div fxFlex="15" fxLayoutAlign="center center">
    <button (click)="onNavigate()" class="navSecretaireButton"><h4>Parametrage<mat-icon>add</mat-icon></h4></button>
  </div>
</div>
<div fxlayout="row wrap" fxLayoutAlign="start start">
  <div fxFlex="100">
    <mat-tab-group fxFlex="100" mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4" animationDuration="0ms">
      <mat-tab class="mattab" label="Imputés">
        <mat-tab-group fxFlex="100" mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4" animationDuration="0ms">
          <mat-tab class="mattab" label="Arrivees">
            <div fxLayout="row" class="tableCourriers">
              <table mat-table
                     [dataSource]="arriveesData" multiTemplateDataRows
                     class="mat-elevation-z8">
                <ng-container matColumnDef="dateArrivee">
                  <th class="thHeader" mat-header-cell *matHeaderCellDef> Date Arrivee </th>
                  <td mat-cell *matCellDef="let element"> {{element.dateArrivee | date:'shortDate'}} </td>
                </ng-container>
                <ng-container matColumnDef="dateCorrespondance">
                  <th class="thHeader" mat-header-cell *matHeaderCellDef> Date Correspondance </th>
                  <td mat-cell *matCellDef="let element"> {{element.dateCorrespondance | date:'shortDate'}} </td>
                </ng-container>
                <ng-container matColumnDef="numeroCorrespondance">
                  <th class="thHeader" mat-header-cell *matHeaderCellDef> Numero Correspondance</th>
                  <td mat-cell *matCellDef="let element"> {{element.numeroCorrespondance}} </td>
                </ng-container>
                <ng-container matColumnDef="expediteur">
                  <th class="thHeader" mat-header-cell *matHeaderCellDef> Expediteur </th>
                  <td mat-cell *matCellDef="let element"> {{element.expediteur}} </td>
                </ng-container>
                <ng-container matColumnDef="objet">
                  <th class="thHeader" mat-header-cell *matHeaderCellDef> Objet </th>
                  <td mat-cell *matCellDef="let element"> {{element.objet}} </td>
                </ng-container>
                <ng-container matColumnDef="imputation">
                  <th class="thHeader" mat-header-cell *matHeaderCellDef> Actions </th>
                  <td mat-cell *matCellDef="let element"><mat-icon (click)="ViewPdf(element.id)" class="matIcon">launch</mat-icon><mat-icon class="matIcon" (click)="ViewDetails(element)">visibility</mat-icon></td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"></tr>
              </table>
            </div>
          </mat-tab>
          <mat-tab label="Departs">
            <div fxLayout="row" class="tableCourriers">
              <table mat-table
                     [dataSource]="departsData" multiTemplateDataRows
                     class="mat-elevation-z8">
                <ng-container matColumnDef="dateDeDepart">
                  <th class="thHeader" mat-header-cell *matHeaderCellDef> Date Depart </th>
                  <td mat-cell *matCellDef="let element"> {{element.dateDeDepart | date:'shortDate'}} </td>
                </ng-container>
                <ng-container matColumnDef="destinataire">
                  <th class="thHeader" mat-header-cell *matHeaderCellDef> Destinataire </th>
                  <td mat-cell *matCellDef="let element"> {{element.destinataire}} </td>
                </ng-container>
                <ng-container matColumnDef="numeroCorrespondance">
                  <th class="thHeader" mat-header-cell *matHeaderCellDef> Numero Correspondance</th>
                  <td mat-cell *matCellDef="let element"> {{element.numeroCorrespondance}} </td>
                </ng-container>
                <ng-container matColumnDef="numeroArchive">
                  <th class="thHeader" mat-header-cell *matHeaderCellDef> Numero Archive </th>
                  <td mat-cell *matCellDef="let element"> {{element.numeroArchive}} </td>
                </ng-container>
                <ng-container matColumnDef="objet">
                  <th class="thHeader" mat-header-cell *matHeaderCellDef> Objet </th>
                  <td mat-cell *matCellDef="let element"> {{element.objet}} </td>
                </ng-container>
                <ng-container matColumnDef="imputation">
                  <th class="thHeader" mat-header-cell *matHeaderCellDef> Actions </th>
                  <td mat-cell *matCellDef="let element"><mat-icon (click)="ViewPdf(element.id)" class="matIcon">launch</mat-icon><mat-icon class="matIcon" (click)="ViewDetailsDepart(element)">visibility</mat-icon></td>
                </ng-container>


                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <tr mat-header-row *matHeaderRowDef="columnsToDisplayPrime"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplayPrime;">
                </tr>
              </table>
            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

