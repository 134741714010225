import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {DirecteurService} from '../../../services/directeur.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-impute-depart',
  templateUrl: './impute-depart.component.html',
  styleUrls: ['./impute-depart.component.scss']
})
export class ImputeDepartComponent implements OnInit {
  imputerForm: FormGroup;

  constructor(
      private formBuilder: FormBuilder,
      private directeurService: DirecteurService ,
      public dialogRef: MatDialogRef<ImputeDepartComponent>
  ) { }

  ngOnInit(): void {
    this.imputerForm = new FormGroup(
        {
          etudesInnovationsPedagogiques: new FormControl(null),
          accueilStages: new FormControl(null),
          intendance: new FormControl(null),
          surveillantGeneral: new FormControl(null),
          programmeUtilisationTerrainsGazonnes: new FormControl(null),
          impute: new FormControl('true'),
          entretientMaintenance: new FormControl(null),
          attribution: new FormControl('false'),
          assistantDirection: new FormControl(null),
          diffusion: new FormControl(null),
          menParler: new FormControl(null),
          etudeAvis: new FormControl(null),
          representationCompteRendu: new FormControl(null),
          information: new FormControl(null),
          suiteDonner: new FormControl(null),
          classement: new FormControl(null),
          autres: new FormControl(null),
          echeance: new FormControl(null),
        });
  }

  // tslint:disable-next-line:typedef
  get f() { return this.imputerForm.controls; }

  // tslint:disable-next-line:typedef
  onSubmit() {
    // alert(this.dialogRef.id) ;
    const formData = new FormData();
    console.log(formData);
    //   this.submitted = true;
// reset alerts on submit
    // @ts-ignore
    // this.alertService.clear();
    // stop here if form is invalid
    this.directeurService.imputeCourrierDepart( this.dialogRef.id , this.imputerForm.value)
        .subscribe(
            user => {
              this.imputerForm.reset() ;
              // window.location.reload() ;
              this.onClose() ;
            });
  }
  // tslint:disable-next-line:typedef
  onClose() {
    this.dialogRef.close() ;
  }
}
