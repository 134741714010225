<div fxlayout="row wrap" fxLayoutAlign="center center" style="position: relative ; top: 50px">
  <div fxFlex="100" style="text-align: center">
    <h2>Bienvenue au CNEPS!</h2>
  </div>
</div>
<div fxlayout="row wrap" fxLayoutAlign="center center" style="position: relative ; top: 50px">
  <div fxFlex="33" style="text-align: center">
    <button class="organigramme">
      LE DIRECTEUR
    </button>
  </div>
</div>
<div fxlayout="row wrap" fxLayoutAlign="center center" style="position: relative ; top: 50px">
  <div fxFlex="33" style="text-align: center">
    <button class="organigramme" routerLink="/app-home-secretaire">
      LE SECRETAIRE
    </button>
  </div>
  <div fxFlex="33" style="text-align: center">
    <button class="organigramme">
      L'INTENDANT
    </button>
  </div>
  <div fxFlex="33" style="text-align: center">
    <button class="organigramme">
      LE SURVEILLANT GENERAL
    </button>
  </div>
</div>
<div fxlayout="row wrap" fxLayoutAlign="center center" style="position: relative ; top: 50px">
  <div fxFlex="33" style="text-align: center">
    <button class="organigramme">
      LA COMPTABILITÉ DES MATIERES
    </button>
  </div>
  <div fxFlex="33" style="text-align: center">
    <button class="organigramme">
      L'AUBERGE
    </button>
  </div>
  <div fxFlex="33" style="text-align: center">
    <button class="organigramme">
      LE PERSONNEL SOLIDE
    </button>
  </div>
</div>
<div fxlayout="row wrap" fxLayoutAlign="center center" style="position: relative ; top: 50px">
  <div fxFlex="33" style="text-align: center">
    <button class="organigramme">
      LA DIVISION D'ACCUEIL ET DE STAGE
    </button>
  </div>
  <div fxFlex="33" style="text-align: center">
    <button class="organigramme">
      LA BIBLIOTHEQUE
    </button>
  </div>
  <div fxFlex="33" style="text-align: center">
    <button class="organigramme">
      LA DIVISION DES ETUDES ET DES INNOVATIONS PEDAGOGIQUES
    </button>
  </div>
</div>
