<div fxlayout="row wrap" fxLayoutAlign="center center">
  <div fxFlex="15" fxLayoutAlign="center center">
    <button (click)="onNavigate()" class="navSecretaireButton"><h4>Parametrage<mat-icon>add</mat-icon></h4></button>
  </div>
  <div fxFlex="15">
    <button (click)="AjoutDossier()" class="navSecretaireButton"><h4>Nouveau Dossier<mat-icon>add</mat-icon></h4></button>
  </div>
</div>
<div fxlayout="row wrap" fxLayoutAlign="start start">
  <div fxFlex="100">
    <mat-tab-group fxFlex="100" mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4" animationDuration="0ms">
      <mat-tab class="mattab" label="Imputés">
        <mat-tab-group fxFlex="100" mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4" animationDuration="0ms">
          <mat-tab class="mattab" label="Arrivees">
            <div fxLayout="row" class="tableCourriers">
              <table mat-table
                     [dataSource]="arriveesData" multiTemplateDataRows
                     class="mat-elevation-z8">
                <ng-container matColumnDef="dateArrivee">
                  <th class="thHeader" mat-header-cell *matHeaderCellDef> Date Arrivee </th>
                  <td mat-cell *matCellDef="let element"> {{element.dateArrivee | date:'shortDate'}} </td>
                </ng-container>
                <ng-container matColumnDef="dateCorrespondance">
                  <th class="thHeader" mat-header-cell *matHeaderCellDef> Date Correspondance </th>
                  <td mat-cell *matCellDef="let element"> {{element.dateCorrespondance | date:'shortDate'}} </td>
                </ng-container>
                <ng-container matColumnDef="numeroCorrespondance">
                  <th class="thHeader" mat-header-cell *matHeaderCellDef> Numero Correspondance</th>
                  <td mat-cell *matCellDef="let element"> {{element.numeroCorrespondance}} </td>
                </ng-container>
                <ng-container matColumnDef="expediteur">
                  <th class="thHeader" mat-header-cell *matHeaderCellDef> Expediteur </th>
                  <td mat-cell *matCellDef="let element"> {{element.expediteur}} </td>
                </ng-container>
                <ng-container matColumnDef="objet">
                  <th class="thHeader" mat-header-cell *matHeaderCellDef> Objet </th>
                  <td mat-cell *matCellDef="let element"> {{element.objet}} </td>
                </ng-container>
                <ng-container matColumnDef="imputation">
                  <th class="thHeader" mat-header-cell *matHeaderCellDef> Actions </th>
                  <td mat-cell *matCellDef="let element"><mat-icon (click)="ViewPdf(element.id)" class="matIcon">launch</mat-icon><mat-icon class="matIcon" (click)="ViewDetails(element)">visibility</mat-icon></td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"></tr>
              </table>
            </div>
          </mat-tab>
          <mat-tab label="Departs">
            <div fxLayout="row" class="tableCourriers">
              <table mat-table
                     [dataSource]="departsData" multiTemplateDataRows
                     class="mat-elevation-z8">
                <ng-container matColumnDef="dateDeDepart">
                  <th class="thHeader" mat-header-cell *matHeaderCellDef> Date Depart </th>
                  <td mat-cell *matCellDef="let element"> {{element.dateDeDepart | date:'shortDate'}} </td>
                </ng-container>
                <ng-container matColumnDef="destinataire">
                  <th class="thHeader" mat-header-cell *matHeaderCellDef> Destinataire </th>
                  <td mat-cell *matCellDef="let element"> {{element.destinataire}} </td>
                </ng-container>
                <ng-container matColumnDef="numeroCorrespondance">
                  <th class="thHeader" mat-header-cell *matHeaderCellDef> Numero Correspondance</th>
                  <td mat-cell *matCellDef="let element"> {{element.numeroCorrespondance}} </td>
                </ng-container>
                <ng-container matColumnDef="numeroArchive">
                  <th class="thHeader" mat-header-cell *matHeaderCellDef> Numero Archive </th>
                  <td mat-cell *matCellDef="let element"> {{element.numeroArchive}} </td>
                </ng-container>
                <ng-container matColumnDef="objet">
                  <th class="thHeader" mat-header-cell *matHeaderCellDef> Objet </th>
                  <td mat-cell *matCellDef="let element"> {{element.objet}} </td>
                </ng-container>
                <ng-container matColumnDef="imputation">
                  <th class="thHeader" mat-header-cell *matHeaderCellDef> Actions </th>
                  <td mat-cell *matCellDef="let element"><mat-icon (click)="ViewPdf(element.id)" class="matIcon">launch</mat-icon><mat-icon class="matIcon" (click)="ViewDetailsDepart(element)">visibility</mat-icon></td>
                </ng-container>


                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <tr mat-header-row *matHeaderRowDef="columnsToDisplayPrime"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplayPrime;">
                </tr>
              </table>
            </div>
          </mat-tab>
        </mat-tab-group>
        </mat-tab>
      <mat-tab class="mattab" label="Mes dossiers">
    <div fxlayout="row" fxLayoutAlign="center center">
      <mat-tab-group fxFlex="100" mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4" animationDuration="0ms">
        <mat-tab *ngFor=" let libelle1 of libelle" label="{{libelle1}}">
          <div *ngIf="libelle1 == 'Ministre'">
            <div class="example-container mat-elevation-z8">
              <table class="audiencesTable" mat-table [dataSource]="Ministre">
                <!-- Name Column -->
                <ng-container matColumnDef="Date">
                  <th mat-header-cell *matHeaderCellDef> Date </th>
                  <td mat-cell *matCellDef="let element"> {{element.date | date :'shortDate'}} </td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="Intitule">
                  <th mat-header-cell *matHeaderCellDef> Intitulé </th>
                  <td mat-cell *matCellDef="let element"> {{element.intitule}} </td>
                </ng-container>

                <!-- Position Column -->
                <ng-container matColumnDef="PieceJointe">
                  <th mat-header-cell *matHeaderCellDef> Piece Jointe </th>
                  <td mat-cell *matCellDef="let element"> <button class="modal-button" (click)="ViewPdf(element.pieceJointe)">Piece Jointe</button> </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
          </div>
          <div *ngIf="libelle1 == 'Directeur'">
            <div class="example-container mat-elevation-z8">
              <table class="audiencesTable" mat-table [dataSource]="Directeur">

                <!-- Name Column -->
                <ng-container matColumnDef="Date">
                  <th mat-header-cell *matHeaderCellDef> Date </th>
                  <td mat-cell *matCellDef="let element"> {{element.date | date :'shortDate'}} </td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="Intitule">
                  <th mat-header-cell *matHeaderCellDef> Intitulé </th>
                  <td mat-cell *matCellDef="let element"> {{element.intitule}} </td>
                </ng-container>

                <!-- Position Column -->
                <ng-container matColumnDef="PieceJointe">
                  <th mat-header-cell *matHeaderCellDef> Piece Jointe </th>
                  <td mat-cell *matCellDef="let element"> <button class="modal-button" (click)="ViewPdf(element.pieceJointe)">Piece Jointe</button> </td>
                </ng-container>



                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
          </div>
          <div *ngIf="libelle1 == 'Adjoint'">
            <div class="example-container mat-elevation-z8">
              <table class="audiencesTable" mat-table [dataSource]="Adjoint">
                <!-- Name Column -->
                <ng-container matColumnDef="Date">
                  <th mat-header-cell *matHeaderCellDef> Date </th>
                  <td mat-cell *matCellDef="let element"> {{element.date | date :'shortDate'}} </td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="Intitule">
                  <th mat-header-cell *matHeaderCellDef> Intitulé </th>
                  <td mat-cell *matCellDef="let element"> {{element.intitule}} </td>
                </ng-container>

                <!-- Position Column -->
                <ng-container matColumnDef="PieceJointe">
                  <th mat-header-cell *matHeaderCellDef> Piece Jointe </th>
                  <td mat-cell *matCellDef="let element"> <button class="modal-button" (click)="ViewPdf(element.pieceJointe)">Piece Jointe</button> </td>
                </ng-container>




                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
          </div>
          <div *ngIf="libelle1 == 'Comptable'">
            <div class="example-container mat-elevation-z8">
              <table class="audiencesTable" mat-table [dataSource]="Comptable">
                <!-- Name Column -->
                <ng-container matColumnDef="Date">
                  <th mat-header-cell *matHeaderCellDef> Date </th>
                  <td mat-cell *matCellDef="let element"> {{element.date | date :'shortDate'}} </td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="Intitule">
                  <th mat-header-cell *matHeaderCellDef> Intitulé </th>
                  <td mat-cell *matCellDef="let element"> {{element.intitule}} </td>
                </ng-container>

                <!-- Position Column -->
                <ng-container matColumnDef="PieceJointe">
                  <th mat-header-cell *matHeaderCellDef> Piece Jointe </th>
                  <td mat-cell *matCellDef="let element"> <button class="modal-button" (click)="ViewPdf(element.pieceJointe)">Piece Jointe</button> </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
          </div>
          <div *ngIf="libelle1 == 'Prefet'">
            <div class="example-container mat-elevation-z8">
              <table class="audiencesTable" mat-table [dataSource]="Prefet">
                <!-- Name Column -->
                <ng-container matColumnDef="Date">
                  <th mat-header-cell *matHeaderCellDef> Date </th>
                  <td mat-cell *matCellDef="let element"> {{element.date | date :'shortDate'}} </td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="Intitule">
                  <th mat-header-cell *matHeaderCellDef> Intitulé </th>
                  <td mat-cell *matCellDef="let element"> {{element.intitule}} </td>
                </ng-container>

                <!-- Position Column -->
                <ng-container matColumnDef="PieceJointe">
                  <th mat-header-cell *matHeaderCellDef> Piece Jointe </th>
                  <td mat-cell *matCellDef="let element"> <button class="modal-button" (click)="ViewPdf(element.pieceJointe)">Piece Jointe</button> </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
          </div>
          <!--<div *ngIf="mois1 == 'Fevrier'">
            <div class="example-container mat-elevation-z8">
              <table mat-table [dataSource]="Fevrier">
                &lt;!&ndash; Position Column &ndash;&gt;
                <ng-container matColumnDef="Jour">
                  <th mat-header-cell *matHeaderCellDef> Jour </th>
                  <td mat-cell *matCellDef="let element"> {{element.jour | date:'shortDate'}} </td>
                </ng-container>

                &lt;!&ndash; Name Column &ndash;&gt;
                <ng-container matColumnDef="Debut">
                  <th mat-header-cell *matHeaderCellDef> Début audience </th>
                  <td mat-cell *matCellDef="let element"> {{element.debutAudience | date :'shortTime'}} </td>
                </ng-container>

                &lt;!&ndash; Weight Column &ndash;&gt;
                <ng-container matColumnDef="Fin">
                  <th mat-header-cell *matHeaderCellDef> Fin audience </th>
                  <td mat-cell *matCellDef="let element"> {{element.finAudience | date :'shortTime'}} </td>
                </ng-container>

                &lt;!&ndash; Symbol Column &ndash;&gt;
                <ng-container matColumnDef="Organisme">
                  <th mat-header-cell *matHeaderCellDef> Organisme ou personne demandeur </th>
                  <td mat-cell *matCellDef="let element"> {{element.demandeur}} </td>
                </ng-container>

                &lt;!&ndash; Symbol Column &ndash;&gt;
                <ng-container matColumnDef="Observation">
                  <th mat-header-cell *matHeaderCellDef> Observations </th>
                  <td mat-cell *matCellDef="let element"> {{element.observations}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></tr>
              </table>
            </div>
          </div>
          <div *ngIf="mois1 == 'Mars'">
            <div class="example-container mat-elevation-z8">
              <table mat-table [dataSource]="Mars">
                &lt;!&ndash; Position Column &ndash;&gt;
                <ng-container matColumnDef="Jour">
                  <th mat-header-cell *matHeaderCellDef> Jour </th>
                  <td mat-cell *matCellDef="let element"> {{element.jour | date:'shortDate'}} </td>
                </ng-container>

                &lt;!&ndash; Name Column &ndash;&gt;
                <ng-container matColumnDef="Debut">
                  <th mat-header-cell *matHeaderCellDef> Début audience </th>
                  <td mat-cell *matCellDef="let element"> {{element.debutAudience | date :'shortTime'}} </td>
                </ng-container>

                &lt;!&ndash; Weight Column &ndash;&gt;
                <ng-container matColumnDef="Fin">
                  <th mat-header-cell *matHeaderCellDef> Fin audience </th>
                  <td mat-cell *matCellDef="let element"> {{element.finAudience | date :'shortTime'}} </td>
                </ng-container>

                &lt;!&ndash; Symbol Column &ndash;&gt;
                <ng-container matColumnDef="Organisme">
                  <th mat-header-cell *matHeaderCellDef> Organisme ou personne demandeur </th>
                  <td mat-cell *matCellDef="let element"> {{element.demandeur}} </td>
                </ng-container>

                &lt;!&ndash; Symbol Column &ndash;&gt;
                <ng-container matColumnDef="Observation">
                  <th mat-header-cell *matHeaderCellDef> Observations </th>
                  <td mat-cell *matCellDef="let element"> {{element.observations}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></tr>
              </table>
            </div>
          </div>
          <div *ngIf="mois1 == 'Avril'">
            <div class="example-container mat-elevation-z8">
              <table mat-table [dataSource]="Avril">
                &lt;!&ndash; Position Column &ndash;&gt;
                <ng-container matColumnDef="Jour">
                  <th mat-header-cell *matHeaderCellDef> Jour </th>
                  <td mat-cell *matCellDef="let element"> {{element.jour | date:'shortDate'}} </td>
                </ng-container>

                &lt;!&ndash; Name Column &ndash;&gt;
                <ng-container matColumnDef="Debut">
                  <th mat-header-cell *matHeaderCellDef> Début audience </th>
                  <td mat-cell *matCellDef="let element"> {{element.debutAudience | date :'shortTime'}} </td>
                </ng-container>

                &lt;!&ndash; Weight Column &ndash;&gt;
                <ng-container matColumnDef="Fin">
                  <th mat-header-cell *matHeaderCellDef> Fin audience </th>
                  <td mat-cell *matCellDef="let element"> {{element.finAudience | date :'shortTime'}} </td>
                </ng-container>

                &lt;!&ndash; Symbol Column &ndash;&gt;
                <ng-container matColumnDef="Organisme">
                  <th mat-header-cell *matHeaderCellDef> Organisme ou personne demandeur </th>
                  <td mat-cell *matCellDef="let element"> {{element.demandeur}} </td>
                </ng-container>

                &lt;!&ndash; Symbol Column &ndash;&gt;
                <ng-container matColumnDef="Observation">
                  <th mat-header-cell *matHeaderCellDef> Observations </th>
                  <td mat-cell *matCellDef="let element"> {{element.observations}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></tr>
              </table>
            </div>
          </div>
          <div *ngIf="mois1 == 'Mai'">
            <div class="example-container mat-elevation-z8">
              <table mat-table [dataSource]="Mai">
                &lt;!&ndash; Position Column &ndash;&gt;
                <ng-container matColumnDef="Jour">
                  <th mat-header-cell *matHeaderCellDef> Jour </th>
                  <td mat-cell *matCellDef="let element"> {{element.jour | date:'shortDate'}} </td>
                </ng-container>

                &lt;!&ndash; Name Column &ndash;&gt;
                <ng-container matColumnDef="Debut">
                  <th mat-header-cell *matHeaderCellDef> Début audience </th>
                  <td mat-cell *matCellDef="let element"> {{element.debutAudience | date :'shortTime'}} </td>
                </ng-container>

                &lt;!&ndash; Weight Column &ndash;&gt;
                <ng-container matColumnDef="Fin">
                  <th mat-header-cell *matHeaderCellDef> Fin audience </th>
                  <td mat-cell *matCellDef="let element"> {{element.finAudience | date :'shortTime'}} </td>
                </ng-container>

                &lt;!&ndash; Symbol Column &ndash;&gt;
                <ng-container matColumnDef="Organisme">
                  <th mat-header-cell *matHeaderCellDef> Organisme ou personne demandeur </th>
                  <td mat-cell *matCellDef="let element"> {{element.demandeur}} </td>
                </ng-container>

                &lt;!&ndash; Symbol Column &ndash;&gt;
                <ng-container matColumnDef="Observation">
                  <th mat-header-cell *matHeaderCellDef> Observations </th>
                  <td mat-cell *matCellDef="let element"> {{element.observations}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></tr>
              </table>
            </div>
          </div>-->
        </mat-tab>
      </mat-tab-group>
    </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
