import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Agenda} from '../Models/agenda.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SecretaireService {
  private API_URL = environment.apiUrl;

  constructor(
    private http: HttpClient,
  ) { }

  // tslint:disable-next-line:typedef
  addArrivee(data: FormData) {
    console.log(data);
    return this.http.post( `${ this.API_URL }/secretaire/CourrierArrivee`, data)
      .pipe ( map (reponse => {
        console.log(reponse);
        return reponse;
      })) ;
  }
  // tslint:disable-next-line:typedef
  addDepart(data: FormData) {
    console.log(data);
    return this.http.post( `${ this.API_URL }/secretaire/CourrierDepart`, data)
      .pipe ( map (reponse => {
        console.log(reponse);
        return reponse;
      })) ;
  }
  // tslint:disable-next-line:typedef
  addAudience(data: any) {
    console.log(data);
    return this.http.post<any>( `${ this.API_URL }/secretaire/audience`, data)
      .pipe ( map (reponse => {
        console.log(reponse);
        alert('yes') ;
        return reponse;
      })) ;
  }
  // tslint:disable-next-line:typedef
  addOrdre(data: any) {
    console.log(data);
    return this.http.post<any>( `${ this.API_URL }/secretaire/ordres`, data)
      .pipe ( map (reponse => {
        console.log(reponse);
        return reponse;
      })) ;
  }
  // tslint:disable-next-line:typedef
  getAll() {
    return this.http.get<any>(`${this.API_URL}/secretaire/CourrierArriveeNimputes`) ;
  }
  // tslint:disable-next-line:typedef
  getAllDeparts() {
    return this.http.get<any>(`${this.API_URL}/secretaire/CourrierDepartNimputes`);
  }
  // tslint:disable-next-line:typedef
  getAllArrivesImputes() {
    return this.http.get<any>(`${this.API_URL}/secretaire/CourrierArriveeImputes`) ;
  }
  // tslint:disable-next-line:typedef
  getAllDepartsImputes() {
    return this.http.get<any>(`${this.API_URL}/secretaire/CourrierDepartImputes`);
  }
  // tslint:disable-next-line:typedef
  getAllArrivesImputesArchive() {
    return this.http.get<any>(`${this.API_URL}/secretaire/CourrierArriveeImputesArchives`) ;
  }
  // tslint:disable-next-line:typedef
  getAllDepartsImputesArchive() {
    return this.http.get<any>(`${this.API_URL}/secretaire/CourrierDepartImputesArchives`);
  }
  // tslint:disable-next-line:typedef
  getAllOrdres() {
    return this.http.get<any>(`${this.API_URL}/secretaire/ordres?archive=false`);
  }
  // tslint:disable-next-line:typedef
  getAllOrdresArchive() {
    return this.http.get<any>(`${this.API_URL}/secretaire/ordres?archive=true`);
  }
// tslint:disable-next-line:typedef
getAllAgenda() {
  return this.http.get<Agenda[]>(`${this.API_URL}/secretaire/audiences`);
}
// tslint:disable-next-line:typedef
  getJanvierArchive() {
    return this.http.get<any>(`${this.API_URL}/secretaire/audiencesJanvierArchive`);
  }
  // tslint:disable-next-line:typedef
  getFevrierArchive() {
    return this.http.get<any>(`${this.API_URL}/secretaire/audiencesFevrierArchive`);
  }
  // tslint:disable-next-line:typedef
  getMarsArchive() {
    return this.http.get<any>(`${this.API_URL}/secretaire/audiencesMarsArchive`);
  }
  // tslint:disable-next-line:typedef
  getAvrilArchive() {
    return this.http.get<any>(`${this.API_URL}/secretaire/audiencesAvrilArchive`);
  }
  // tslint:disable-next-line:typedef
  getMaiArchive() {
    return this.http.get<Agenda>(`${this.API_URL}/secretaire/audiencesMaiArchive`);
  }
  // tslint:disable-next-line:typedef
  getJuinArchive() {
    return this.http.get<any>(`${this.API_URL}/secretaire/audiencesJuinArchive`);
  }
  // tslint:disable-next-line:typedef
  getJuilletArchive() {
    return this.http.get<any>(`${this.API_URL}/secretaire/audiencesJuilletArchive`);
  }
  // tslint:disable-next-line:typedef
  getAoutArchive() {
    return this.http.get<any>(`${this.API_URL}/secretaire/audiencesAoutArchive`);
  }
  // tslint:disable-next-line:typedef
  getSeptembreArchive() {
    return this.http.get<any>(`${this.API_URL}/secretaire/audiencesSeptembreArchive`);
  }
  // tslint:disable-next-line:typedef
  getOctobreArchive() {
    return this.http.get<any>(`${this.API_URL}/secretaire/audiencesOctobreArchive`);
  }
  // tslint:disable-next-line:typedef
  getNovembreArchive() {
    return this.http.get<any>(`${this.API_URL}/secretaire/audiencesNovembreArchive`);
  }
  // tslint:disable-next-line:typedef
  getDecembreArchive() {
    return this.http.get<any>(`${this.API_URL}/secretaire/audiencesDecembreArchive`);
  }
  // tslint:disable-next-line:typedef
  getJanvier() {
    return this.http.get<any>(`${this.API_URL}/secretaire/audiencesJanvier?archive=false`);
  }
  // tslint:disable-next-line:typedef
  getFevrier() {
    return this.http.get<any>(`${this.API_URL}/secretaire/audiencesFevrier?archive=false`);
  }
  // tslint:disable-next-line:typedef
  getMars() {
    return this.http.get<any>(`${this.API_URL}/secretaire/audiencesMars?archive=false`);
  }
  // tslint:disable-next-line:typedef
  getAvril() {
    return this.http.get<any>(`${this.API_URL}/secretaire/audiencesAvril?archive=false`);
  }
  // tslint:disable-next-line:typedef
  getMai() {
    return this.http.get<Agenda>(`${this.API_URL}/secretaire/audiencesMai?archive=false`);
  }
  // tslint:disable-next-line:typedef
  getJuin() {
    return this.http.get<any>(`${this.API_URL}/secretaire/audiencesJuin?archive=false`);
  }
  // tslint:disable-next-line:typedef
  getJuillet() {
    return this.http.get<any>(`${this.API_URL}/secretaire/audiencesJuillet?archive=false`);
  }
  // tslint:disable-next-line:typedef
  getAout() {
    return this.http.get<any>(`${this.API_URL}/secretaire/audiencesAout?archive=false`);
  }
  // tslint:disable-next-line:typedef
  getSeptembre() {
    return this.http.get<any>(`${this.API_URL}/secretaire/audiencesSeptembre?archive=false`);
  }
  // tslint:disable-next-line:typedef
  getOctobre() {
    return this.http.get<any>(`${this.API_URL}/secretaire/audiencesOctobre?archive=false`);
  }
  // tslint:disable-next-line:typedef
  getNovembre() {
    return this.http.get<any>(`${this.API_URL}/secretaire/audiencesNovembre?archive=false`);
  }
  // tslint:disable-next-line:typedef
  getDecembre() {
    return this.http.get<any>(`${this.API_URL}/secretaire/audiencesDecembre?archive=false`);
  }
  // tslint:disable-next-line:typedef
  getCourrierPdf(id) {
    return this.http.get<any>(`${this.API_URL}/courrier_arrivees/${id}`);
  }
  // tslint:disable-next-line:typedef
  getCourrierDepartPdf(id) {
    return this.http.get<any>(`${this.API_URL}/courrier_departs/${id}`);
  }
}
