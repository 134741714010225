import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {DirecteurService} from '../../../services/directeur.service';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-details-audience',
  templateUrl: './details-audience.component.html',
  styleUrls: ['./details-audience.component.scss']
})
export class DetailsAudienceComponent implements OnInit {
  src: any ;
  archiveAudienceForm: FormGroup;

  constructor(
      public dialogRef: MatDialogRef<DetailsAudienceComponent>,
      private directeurService: DirecteurService ,
  ) { }

  ngOnInit(): void {
    this.src = this.dialogRef.id ;
    console.log(this.src) ;
    this.archiveAudienceForm = new FormGroup(
        {
          observations: new FormControl(true),
        });
  }
  // tslint:disable-next-line:typedef
  archiverAudience(id: any) {
    console.log(id) ;
    //  console.log(this.archiveForm.value) ;
    // alert(this.dialogRef.id) ;
    //   this.submitted = true;
// reset alerts on submit
    // @ts-ignore
    // this.alertService.clear();
    // stop here if form is invalid
    this.directeurService.archiveAudience( id , this.archiveAudienceForm.value)
        .subscribe(
            user => {
              this.onClose() ;
              // window.location.reload() ;
            });
  }
  // tslint:disable-next-line:typedef
  onClose() {
    this.dialogRef.close() ;
  }
}
