<div fxlayout="row wrap" fxLayoutAlign="start start">
  <div fxFlex="100">
    <div fxlayout="row" fxLayoutAlign="end end">
      <div fxFlex="15">
        <button (click)="createArrive()" class="navSecretaireButton"><h4>Arrivée<mat-icon>add</mat-icon></h4></button>
      </div>
      <div fxFlex="15">
        <button (click)="createDepart()" class="navSecretaireButton"><h4>Depart<mat-icon>add</mat-icon></h4></button>
      </div>
      <div fxFlex="15">
        <button (click)="AjoutAudience()" class="navSecretaireButton"><h4>Audience<mat-icon>add</mat-icon></h4></button>
      </div>
      <div fxFlex="15">
        <button (click)="createOrdre()" class="navSecretaireButton"><h4>Mission<mat-icon>add</mat-icon></h4></button>
      </div>
    </div>
    <div fxlayout="row" fxLayoutAlign="center center">
      <h2>Mes Courriers</h2>
    </div>
    <div fxlayout="row" fxLayoutAlign="center center">
      <mat-tab-group fxFlex="100" mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4" animationDuration="0ms">
        <mat-tab class="mattab" label="Arrivees">
          <div fxLayout="row" class="tableCourriers">
            <table mat-table
                   [dataSource]="dataSource" multiTemplateDataRows
                   class="mat-elevation-z8">
              <ng-container matColumnDef="dateArrivee">
                <th class="thHeader" mat-header-cell *matHeaderCellDef> Date Arrivee </th>
                <td mat-cell *matCellDef="let element"> {{element.dateArrivee | date:'shortDate'}} </td>
              </ng-container>
              <ng-container matColumnDef="dateCorrespondance">
                <th class="thHeader" mat-header-cell *matHeaderCellDef> Date Correspondance </th>
                <td mat-cell *matCellDef="let element"> {{element.dateCorrespondance | date:'shortDate'}} </td>
              </ng-container>
              <ng-container matColumnDef="numeroCorrespondance">
                <th class="thHeader" mat-header-cell *matHeaderCellDef> Numero Correspondance</th>
                <td mat-cell *matCellDef="let element"> {{element.numeroCorrespondance}} </td>
              </ng-container>
              <ng-container matColumnDef="expediteur">
                <th class="thHeader" mat-header-cell *matHeaderCellDef> Expediteur </th>
                <td mat-cell *matCellDef="let element"> {{element.expediteur}} </td>
              </ng-container>
              <ng-container matColumnDef="objet">
                <th class="thHeader" mat-header-cell *matHeaderCellDef> Objet </th>
                <td mat-cell *matCellDef="let element"> {{element.objet}} </td>
              </ng-container>
              <ng-container matColumnDef="imputation">
                <th class="thHeader" mat-header-cell *matHeaderCellDef> Actions </th>
                <td mat-cell *matCellDef="let element"><mat-icon (click)="ViewPdf(element.id)" class="matIcon">launch</mat-icon><mat-icon class="matIconD" (click)="deleteArrivee(element.id)">delete_outline</mat-icon></td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
              <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"></tr>
            </table>
          </div>
        </mat-tab>
        <mat-tab label="Departs">
          <div fxLayout="row" class="tableCourriers">
            <table mat-table
                   [dataSource]="dataSource2" multiTemplateDataRows
                   class="mat-elevation-z8">
              <ng-container matColumnDef="dateDeDepart">
                <th class="thHeader" mat-header-cell *matHeaderCellDef> Date Depart </th>
                <td mat-cell *matCellDef="let element"> {{element.dateDeDepart | date:'shortDate'}} </td>
              </ng-container>
              <ng-container matColumnDef="destinataire">
                <th class="thHeader" mat-header-cell *matHeaderCellDef> Destinataire </th>
                <td mat-cell *matCellDef="let element"> {{element.destinataire}} </td>
              </ng-container>
              <ng-container matColumnDef="numeroCorrespondance">
                <th class="thHeader" mat-header-cell *matHeaderCellDef> Numero Correspondance</th>
                <td mat-cell *matCellDef="let element"> {{element.numeroCorrespondance}} </td>
              </ng-container>
              <ng-container matColumnDef="numeroArchive">
                <th class="thHeader" mat-header-cell *matHeaderCellDef> Numero Archive </th>
                <td mat-cell *matCellDef="let element"> {{element.numeroArchive}} </td>
              </ng-container>
              <ng-container matColumnDef="objet">
                <th class="thHeader" mat-header-cell *matHeaderCellDef> Objet </th>
                <td mat-cell *matCellDef="let element"> {{element.objet}} </td>
              </ng-container>
              <ng-container matColumnDef="imputation">
                <th class="thHeader" mat-header-cell *matHeaderCellDef> Actions </th>
                <td mat-cell *matCellDef="let element"><mat-icon (click)="ViewPdfDepart(element.id)" class="matIcon">launch</mat-icon><mat-icon class="matIconD" (click)="deleteDepart(element.id)">delete_outline</mat-icon></td>
              </ng-container>


              <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
              <tr mat-header-row *matHeaderRowDef="columnsToDisplayPrime"></tr>
              <tr mat-row *matRowDef="let element; columns: columnsToDisplayPrime;">
              </tr>
            </table>
          </div>
        </mat-tab>
        <mat-tab label="Suivi des imputations">
          <mat-tab-group fxFlex="100" mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4" animationDuration="0ms">
            <mat-tab class="mattab" label="Arrivees">
              <div fxLayout="row" class="tableCourriers">
                <table mat-table
                       [dataSource]="arriveesDataImputes" multiTemplateDataRows
                       class="mat-elevation-z8">
                  <ng-container matColumnDef="dateArrivee">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Date Arrivee </th>
                    <td mat-cell *matCellDef="let element"> {{element.dateArrivee | date:'shortDate'}} </td>
                  </ng-container>
                  <ng-container matColumnDef="echeance">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Echeance </th>
                    <td mat-cell *matCellDef="let element"> {{element.echeance | date:'shortDate'}} </td>
                  </ng-container>
                  <ng-container matColumnDef="objet">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Objet </th>
                    <td mat-cell *matCellDef="let element"> {{element.objet}} </td>
                  </ng-container>
                  <ng-container matColumnDef="expediteur">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Expediteur </th>
                    <td mat-cell *matCellDef="let element"> {{element.expediteur}} </td>
                  </ng-container>
                  <ng-container matColumnDef="actions">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Actions </th>
                    <td mat-cell *matCellDef="let element"><mat-icon (click)="ViewPdf(element.id)" class="matIcon">launch</mat-icon><mat-icon class="matIcon" (click)="ViewDetails(element)">visibility</mat-icon><mat-icon class="matIconD" (click)="deleteArrivee(element.id)">delete_outline</mat-icon></td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="columnsToDisplaySecond"></tr>
                  <tr mat-row *matRowDef="let element; columns: columnsToDisplaySecond;"></tr>
                </table>
              </div>
            </mat-tab>
            <mat-tab label="Departs">
              <div fxLayout="row" class="tableCourriers">
                <table mat-table
                       [dataSource]="departsDataImputes" multiTemplateDataRows
                       class="mat-elevation-z8">
                  <ng-container matColumnDef="dateDeDepart">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Date Depart </th>
                    <td mat-cell *matCellDef="let element"> {{element.dateDeDepart | date:'shortDate'}} </td>
                  </ng-container>
                  <ng-container matColumnDef="echeance">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Echeance </th>
                    <td mat-cell *matCellDef="let element"> {{element.echeance | date:'shortDate'}} </td>
                  </ng-container>
                  <ng-container matColumnDef="objet">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Objet </th>
                    <td mat-cell *matCellDef="let element"> {{element.objet}} </td>
                  </ng-container>
                  <ng-container matColumnDef="destinataire">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Destinataire </th>
                    <td mat-cell *matCellDef="let element"> {{element.destinataire}} </td>
                  </ng-container>
                  <ng-container matColumnDef="actions">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Actions </th>
                    <td mat-cell *matCellDef="let element"><mat-icon (click)="ViewPdfDepart(element.id)" class="matIcon">launch</mat-icon><mat-icon class="matIcon" (click)="ViewDetails(element)">visibility</mat-icon><mat-icon class="matIconD" (click)="deleteDepart(element.id)">delete_outline</mat-icon></td>
                  </ng-container>


                  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                  <tr mat-header-row *matHeaderRowDef="columnsToDisplayTroisieme"></tr>
                  <tr mat-row *matRowDef="let element; columns: columnsToDisplayTroisieme;">
                  </tr>
                </table>
              </div>
            </mat-tab>
          </mat-tab-group>
        </mat-tab>
        <mat-tab label="Archives des imputations">
          <mat-tab-group fxFlex="100" mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4" animationDuration="0ms">
            <mat-tab class="mattab" label="Arrivees">
              <div fxLayout="row" class="tableCourriers">
                <table mat-table
                       [dataSource]="arriveesDataImputesArchives" multiTemplateDataRows
                       class="mat-elevation-z8">
                  <ng-container matColumnDef="dateArrivee">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Date Arrivee </th>
                    <td mat-cell *matCellDef="let element"> {{element.dateArrivee | date:'shortDate'}} </td>
                  </ng-container>
                  <ng-container matColumnDef="echeance">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Echeance </th>
                    <td mat-cell *matCellDef="let element"> {{element.echeance | date:'shortDate'}} </td>
                  </ng-container>
                  <ng-container matColumnDef="objet">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Objet </th>
                    <td mat-cell *matCellDef="let element"> {{element.objet}} </td>
                  </ng-container>
                  <ng-container matColumnDef="expediteur">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Expediteur </th>
                    <td mat-cell *matCellDef="let element"> {{element.expediteur}} </td>
                  </ng-container>
                  <ng-container matColumnDef="actions">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Actions </th>
                    <td mat-cell *matCellDef="let element"><mat-icon (click)="ViewPdf(element.id)" class="matIcon">launch</mat-icon><mat-icon class="matIcon" (click)="ViewDetails(element)">visibility</mat-icon><mat-icon class="matIconD" (click)="deleteArrivee(element.id)">delete_outline</mat-icon></td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="columnsToDisplaySecond"></tr>
                  <tr mat-row *matRowDef="let element; columns: columnsToDisplaySecond;"></tr>
                </table>
              </div>
            </mat-tab>
            <mat-tab label="Departs">
              <div fxLayout="row" class="tableCourriers">
                <table mat-table
                       [dataSource]="departsDataImputesArchives" multiTemplateDataRows
                       class="mat-elevation-z8">
                  <ng-container matColumnDef="dateDeDepart">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Date Depart </th>
                    <td mat-cell *matCellDef="let element"> {{element.dateDeDepart | date:'shortDate'}} </td>
                  </ng-container>
                  <ng-container matColumnDef="echeance">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Echeance </th>
                    <td mat-cell *matCellDef="let element"> {{element.echeance | date:'shortDate'}} </td>
                  </ng-container>
                  <ng-container matColumnDef="objet">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Objet </th>
                    <td mat-cell *matCellDef="let element"> {{element.objet}} </td>
                  </ng-container>
                  <ng-container matColumnDef="destinataire">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Destinataire </th>
                    <td mat-cell *matCellDef="let element"> {{element.destinataire}} </td>
                  </ng-container>
                  <ng-container matColumnDef="actions">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Actions </th>
                    <td mat-cell *matCellDef="let element"><mat-icon (click)="ViewPdfDepart(element.id)" class="matIcon">launch</mat-icon><mat-icon class="matIcon" (click)="ViewDetails(element)">visibility</mat-icon><mat-icon class="matIconD" (click)="deleteDepart(element.id)">delete_outline</mat-icon></td>
                  </ng-container>


                  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                  <tr mat-header-row *matHeaderRowDef="columnsToDisplayTroisieme"></tr>
                  <tr mat-row *matRowDef="let element; columns: columnsToDisplayTroisieme;">
                  </tr>
                </table>
              </div>
            </mat-tab>
          </mat-tab-group>
        </mat-tab>
        <mat-tab label="Ordres de mission">
          <mat-tab-group fxFlex="100" mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4" animationDuration="0ms">
            <mat-tab class="mattab" label="En cours">
              <div fxLayout="row" class="tableCourriers">
                <table mat-table
                       [dataSource]="ordreData" multiTemplateDataRows
                       class="mat-elevation-z8">
                  <ng-container matColumnDef="Nom">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Nom complet </th>
                    <td mat-cell *matCellDef="let element"> {{element.nomComplet}} </td>
                  </ng-container>
                  <ng-container matColumnDef="Fonction">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Fonction </th>
                    <td mat-cell *matCellDef="let element"> {{element.fonction}} </td>
                  </ng-container>
                  <ng-container matColumnDef="Date">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Date </th>
                    <td mat-cell *matCellDef="let element"> {{element.date | date:'shortDate'}} </td>
                  </ng-container>
                  <ng-container matColumnDef="Lieu">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Lieu </th>
                    <td mat-cell *matCellDef="let element"> {{element.lieu}} </td>
                  </ng-container>
                  <ng-container matColumnDef="Intitule">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Intitule </th>
                    <td mat-cell *matCellDef="let element"> {{element.intitule}} </td>
                  </ng-container>
                  <ng-container matColumnDef="Description">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Description </th>
                    <td mat-cell *matCellDef="let element"> {{element.description}} </td>
                  </ng-container>
                  <ng-container matColumnDef="Actions">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Actions </th>
                    <td mat-cell *matCellDef="let element"><mat-icon class="matIconD" (click)="deleteOrdre(element.id)">delete_outline</mat-icon></td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumnsTroisieme"></tr>
                  <tr mat-row *matRowDef="let element; columns: displayedColumnsTroisieme;"></tr>
                </table>
              </div>
            </mat-tab>
            <mat-tab class="mattab" label="Archives">
              <div fxLayout="row" class="tableCourriers">
                <table mat-table
                       [dataSource]="ordreDataArchive" multiTemplateDataRows
                       class="mat-elevation-z8">
                  <ng-container matColumnDef="Nom">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Nom complet </th>
                    <td mat-cell *matCellDef="let element"> {{element.nomComplet}} </td>
                  </ng-container>
                  <ng-container matColumnDef="Fonction">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Fonction </th>
                    <td mat-cell *matCellDef="let element"> {{element.fonction}} </td>
                  </ng-container>
                  <ng-container matColumnDef="Date">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Date </th>
                    <td mat-cell *matCellDef="let element"> {{element.date | date:'shortDate'}} </td>
                  </ng-container>
                  <ng-container matColumnDef="Lieu">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Lieu </th>
                    <td mat-cell *matCellDef="let element"> {{element.lieu}} </td>
                  </ng-container>
                  <ng-container matColumnDef="Intitule">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Intitule </th>
                    <td mat-cell *matCellDef="let element"> {{element.intitule}} </td>
                  </ng-container>
                  <ng-container matColumnDef="Description">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Description </th>
                    <td mat-cell *matCellDef="let element"> {{element.description}} </td>
                  </ng-container>
                  <ng-container matColumnDef="Actions">
                    <th class="thHeader" mat-header-cell *matHeaderCellDef> Actions </th>
                    <td mat-cell *matCellDef="let element"><mat-icon class="matIconD" (click)="deleteOrdre(element.id)">delete_outline</mat-icon></td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumnsTroisieme"></tr>
                  <tr mat-row *matRowDef="let element; columns: displayedColumnsTroisieme;"></tr>
                </table>
              </div>
            </mat-tab>
          </mat-tab-group>
        </mat-tab>
        <mat-tab label="Audiences">
          <mat-tab-group fxFlex="100" mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4" animationDuration="0ms">
            <mat-tab class="mattab" label="A venir">
              <div fxLayout="row" fxLayoutAlign="center center" style="position: relative ; top: 40px">
                <h2>Suivis des audiences</h2>
              </div>
              <div fxLayout="row" fxFlex="90" class="audiences" fxLayoutAlign="center" style="position: relative ; top: 40px">
                <mat-tab-group fxFlex="90" class="mat-elevation-z4" animationDuration="0ms">
                  <mat-tab *ngFor=" let mois1 of mois" label="{{mois1}}">
                    <div *ngIf="mois1 == 'Janvier'">
                      <div class="example-container mat-elevation-z8">
                        <table class="audiencesTable" mat-table [dataSource]="Janvier">
                          <!-- Position Column -->
                          <ng-container matColumnDef="Jour">
                            <th mat-header-cell *matHeaderCellDef> Jour </th>
                            <td mat-cell *matCellDef="let element"> {{element.jour | date:'shortDate'}} </td>
                          </ng-container>

                          <!-- Name Column -->
                          <ng-container matColumnDef="Debut">
                            <th mat-header-cell *matHeaderCellDef> Début audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.debutAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Weight Column -->
                          <ng-container matColumnDef="Fin">
                            <th mat-header-cell *matHeaderCellDef> Fin audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.finAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Organisme">
                            <th mat-header-cell *matHeaderCellDef> Organisme ou personne demandeur </th>
                            <td mat-cell *matCellDef="let element"> {{element.demandeur}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Observation">
                            <th mat-header-cell *matHeaderCellDef> Observations </th>
                            <td mat-cell *matCellDef="let element"> <mat-icon class="matIcon" (click)="ViewDetailsAudience(element)">visibility</mat-icon></td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></tr>
                        </table>
                      </div>
                    </div>
                    <div *ngIf="mois1 == 'Fevrier'">
                      <div class="example-container mat-elevation-z8">
                        <table mat-table [dataSource]="Fevrier">
                          <!-- Position Column -->
                          <ng-container matColumnDef="Jour">
                            <th mat-header-cell *matHeaderCellDef> Jour </th>
                            <td mat-cell *matCellDef="let element"> {{element.jour | date:'shortDate'}} </td>
                          </ng-container>

                          <!-- Name Column -->
                          <ng-container matColumnDef="Debut">
                            <th mat-header-cell *matHeaderCellDef> Début audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.debutAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Weight Column -->
                          <ng-container matColumnDef="Fin">
                            <th mat-header-cell *matHeaderCellDef> Fin audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.finAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Organisme">
                            <th mat-header-cell *matHeaderCellDef> Organisme ou personne demandeur </th>
                            <td mat-cell *matCellDef="let element"> {{element.demandeur}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Observation">
                            <th mat-header-cell *matHeaderCellDef> Observations </th>
                            <td mat-cell *matCellDef="let element"> <mat-icon class="matIcon" (click)="ViewDetailsAudience(element)">visibility</mat-icon></td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></tr>
                        </table>
                      </div>
                    </div>
                    <div *ngIf="mois1 == 'Mars'">
                      <div class="example-container mat-elevation-z8">
                        <table mat-table [dataSource]="Mars">
                          <!-- Position Column -->
                          <ng-container matColumnDef="Jour">
                            <th mat-header-cell *matHeaderCellDef> Jour </th>
                            <td mat-cell *matCellDef="let element"> {{element.jour | date:'shortDate'}} </td>
                          </ng-container>

                          <!-- Name Column -->
                          <ng-container matColumnDef="Debut">
                            <th mat-header-cell *matHeaderCellDef> Début audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.debutAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Weight Column -->
                          <ng-container matColumnDef="Fin">
                            <th mat-header-cell *matHeaderCellDef> Fin audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.finAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Organisme">
                            <th mat-header-cell *matHeaderCellDef> Organisme ou personne demandeur </th>
                            <td mat-cell *matCellDef="let element"> {{element.demandeur}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Observation">
                            <th mat-header-cell *matHeaderCellDef> Observations </th>
                            <td mat-cell *matCellDef="let element"> <mat-icon class="matIcon" (click)="ViewDetailsAudience(element)">visibility</mat-icon></td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></tr>
                        </table>
                      </div>
                    </div>
                    <div *ngIf="mois1 == 'Avril'">
                      <div class="example-container mat-elevation-z8">
                        <table mat-table [dataSource]="Avril">
                          <!-- Position Column -->
                          <ng-container matColumnDef="Jour">
                            <th mat-header-cell *matHeaderCellDef> Jour </th>
                            <td mat-cell *matCellDef="let element"> {{element.jour | date:'shortDate'}} </td>
                          </ng-container>

                          <!-- Name Column -->
                          <ng-container matColumnDef="Debut">
                            <th mat-header-cell *matHeaderCellDef> Début audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.debutAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Weight Column -->
                          <ng-container matColumnDef="Fin">
                            <th mat-header-cell *matHeaderCellDef> Fin audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.finAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Organisme">
                            <th mat-header-cell *matHeaderCellDef> Organisme ou personne demandeur </th>
                            <td mat-cell *matCellDef="let element"> {{element.demandeur}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Observation">
                            <th mat-header-cell *matHeaderCellDef> Observations </th>
                            <td mat-cell *matCellDef="let element"> <mat-icon class="matIcon" (click)="ViewDetailsAudience(element)">visibility</mat-icon></td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></tr>
                        </table>
                      </div>
                    </div>
                    <div *ngIf="mois1 == 'Mai'">
                      <div class="example-container mat-elevation-z8">
                        <table mat-table [dataSource]="Mai">
                          <!-- Position Column -->
                          <ng-container matColumnDef="Jour">
                            <th mat-header-cell *matHeaderCellDef> Jour </th>
                            <td mat-cell *matCellDef="let element"> {{element.jour | date:'shortDate'}} </td>
                          </ng-container>

                          <!-- Name Column -->
                          <ng-container matColumnDef="Debut">
                            <th mat-header-cell *matHeaderCellDef> Début audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.debutAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Weight Column -->
                          <ng-container matColumnDef="Fin">
                            <th mat-header-cell *matHeaderCellDef> Fin audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.finAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Organisme">
                            <th mat-header-cell *matHeaderCellDef> Organisme ou personne demandeur </th>
                            <td mat-cell *matCellDef="let element"> {{element.demandeur}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Observation">
                            <th mat-header-cell *matHeaderCellDef> Observations </th>
                            <td mat-cell *matCellDef="let element"><mat-icon class="matIcon" (click)="ViewDetailsAudience(element)">visibility</mat-icon></td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></tr>
                        </table>
                      </div>
                    </div>
                    <div *ngIf="mois1 == 'Juin'">
                      <div class="example-container mat-elevation-z8">
                        <table mat-table [dataSource]="Juin">
                          <!-- Position Column -->
                          <ng-container matColumnDef="Jour">
                            <th mat-header-cell *matHeaderCellDef> Jour </th>
                            <td mat-cell *matCellDef="let element"> {{element.jour | date:'shortDate'}} </td>
                          </ng-container>

                          <!-- Name Column -->
                          <ng-container matColumnDef="Debut">
                            <th mat-header-cell *matHeaderCellDef> Début audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.debutAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Weight Column -->
                          <ng-container matColumnDef="Fin">
                            <th mat-header-cell *matHeaderCellDef> Fin audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.finAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Organisme">
                            <th mat-header-cell *matHeaderCellDef> Organisme ou personne demandeur </th>
                            <td mat-cell *matCellDef="let element"> {{element.demandeur}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Observation">
                            <th mat-header-cell *matHeaderCellDef> Observations </th>
                            <td mat-cell *matCellDef="let element"> <mat-icon class="matIcon" (click)="ViewDetailsAudience(element)">visibility</mat-icon></td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></tr>
                        </table>
                      </div>
                    </div>
                    <div *ngIf="mois1 == 'Juillet'">
                      <div class="example-container mat-elevation-z8">
                        <table mat-table [dataSource]="Juillet">
                          <!-- Position Column -->
                          <ng-container matColumnDef="Jour">
                            <th mat-header-cell *matHeaderCellDef> Jour </th>
                            <td mat-cell *matCellDef="let element"> {{element.jour | date:'shortDate'}} </td>
                          </ng-container>

                          <!-- Name Column -->
                          <ng-container matColumnDef="Debut">
                            <th mat-header-cell *matHeaderCellDef> Début audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.debutAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Weight Column -->
                          <ng-container matColumnDef="Fin">
                            <th mat-header-cell *matHeaderCellDef> Fin audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.finAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Organisme">
                            <th mat-header-cell *matHeaderCellDef> Organisme ou personne demandeur </th>
                            <td mat-cell *matCellDef="let element"> {{element.demandeur}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Observation">
                            <th mat-header-cell *matHeaderCellDef> Observations </th>
                            <td mat-cell *matCellDef="let element"> <mat-icon class="matIcon" (click)="ViewDetailsAudience(element)">visibility</mat-icon></td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></tr>
                        </table>
                      </div>
                    </div>
                    <div *ngIf="mois1 == 'Aout'">
                      <div class="example-container mat-elevation-z8">
                        <table mat-table [dataSource]="Aout">
                          <!-- Position Column -->
                          <ng-container matColumnDef="Jour">
                            <th mat-header-cell *matHeaderCellDef> Jour </th>
                            <td mat-cell *matCellDef="let element"> {{element.jour | date:'shortDate'}} </td>
                          </ng-container>

                          <!-- Name Column -->
                          <ng-container matColumnDef="Debut">
                            <th mat-header-cell *matHeaderCellDef> Début audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.debutAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Weight Column -->
                          <ng-container matColumnDef="Fin">
                            <th mat-header-cell *matHeaderCellDef> Fin audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.finAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Organisme">
                            <th mat-header-cell *matHeaderCellDef> Organisme ou personne demandeur </th>
                            <td mat-cell *matCellDef="let element"> {{element.demandeur}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Observation">
                            <th mat-header-cell *matHeaderCellDef> Observations </th>
                            <td mat-cell *matCellDef="let element"> <mat-icon class="matIcon" (click)="ViewDetailsAudience(element)">visibility</mat-icon></td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></tr>
                        </table>
                      </div>
                    </div>
                    <div *ngIf="mois1 == 'Septembre'">
                      <div class="example-container mat-elevation-z8">
                        <table mat-table [dataSource]="Septembre">
                          <!-- Position Column -->
                          <ng-container matColumnDef="Jour">
                            <th mat-header-cell *matHeaderCellDef> Jour </th>
                            <td mat-cell *matCellDef="let element"> {{element.jour | date:'shortDate'}} </td>
                          </ng-container>

                          <!-- Name Column -->
                          <ng-container matColumnDef="Debut">
                            <th mat-header-cell *matHeaderCellDef> Début audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.debutAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Weight Column -->
                          <ng-container matColumnDef="Fin">
                            <th mat-header-cell *matHeaderCellDef> Fin audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.finAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Organisme">
                            <th mat-header-cell *matHeaderCellDef> Organisme ou personne demandeur </th>
                            <td mat-cell *matCellDef="let element"> {{element.demandeur}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Observation">
                            <th mat-header-cell *matHeaderCellDef> Observations </th>
                            <td mat-cell *matCellDef="let element"> <mat-icon class="matIcon" (click)="ViewDetailsAudience(element)">visibility</mat-icon></td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></tr>
                        </table>
                      </div>
                    </div>
                    <div *ngIf="mois1 == 'Octobre'">
                      <div class="example-container mat-elevation-z8">
                        <table mat-table [dataSource]="Octobre">
                          <!-- Position Column -->
                          <ng-container matColumnDef="Jour">
                            <th mat-header-cell *matHeaderCellDef> Jour </th>
                            <td mat-cell *matCellDef="let element"> {{element.jour | date:'shortDate'}} </td>
                          </ng-container>

                          <!-- Name Column -->
                          <ng-container matColumnDef="Debut">
                            <th mat-header-cell *matHeaderCellDef> Début audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.debutAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Weight Column -->
                          <ng-container matColumnDef="Fin">
                            <th mat-header-cell *matHeaderCellDef> Fin audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.finAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Organisme">
                            <th mat-header-cell *matHeaderCellDef> Organisme ou personne demandeur </th>
                            <td mat-cell *matCellDef="let element"> {{element.demandeur}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Observation">
                            <th mat-header-cell *matHeaderCellDef> Observations </th>
                            <td mat-cell *matCellDef="let element"> <mat-icon class="matIcon" (click)="ViewDetailsAudience(element)">visibility</mat-icon></td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></tr>
                        </table>
                      </div>
                    </div>
                    <div *ngIf="mois1 == 'Novembre'">
                      <div class="example-container mat-elevation-z8">
                        <table mat-table [dataSource]="Novembre">
                          <!-- Position Column -->
                          <ng-container matColumnDef="Jour">
                            <th mat-header-cell *matHeaderCellDef> Jour </th>
                            <td mat-cell *matCellDef="let element"> {{element.jour | date:'shortDate'}} </td>
                          </ng-container>

                          <!-- Name Column -->
                          <ng-container matColumnDef="Debut">
                            <th mat-header-cell *matHeaderCellDef> Début audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.debutAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Weight Column -->
                          <ng-container matColumnDef="Fin">
                            <th mat-header-cell *matHeaderCellDef> Fin audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.finAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Organisme">
                            <th mat-header-cell *matHeaderCellDef> Organisme ou personne demandeur </th>
                            <td mat-cell *matCellDef="let element"> {{element.demandeur}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Observation">
                            <th mat-header-cell *matHeaderCellDef> Observations </th>
                            <td mat-cell *matCellDef="let element"> <mat-icon class="matIcon" (click)="ViewDetailsAudience(element)">visibility</mat-icon></td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></tr>
                        </table>
                      </div>
                    </div>
                    <div *ngIf="mois1 == 'Decembre'">
                      <div class="example-container mat-elevation-z8">
                        <table mat-table [dataSource]="Decembre">
                          <!-- Position Column -->
                          <ng-container matColumnDef="Jour">
                            <th mat-header-cell *matHeaderCellDef> Jour </th>
                            <td mat-cell *matCellDef="let element"> {{element.jour | date:'shortDate'}} </td>
                          </ng-container>

                          <!-- Name Column -->
                          <ng-container matColumnDef="Debut">
                            <th mat-header-cell *matHeaderCellDef> Début audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.debutAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Weight Column -->
                          <ng-container matColumnDef="Fin">
                            <th mat-header-cell *matHeaderCellDef> Fin audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.finAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Organisme">
                            <th mat-header-cell *matHeaderCellDef> Organisme ou personne demandeur </th>
                            <td mat-cell *matCellDef="let element"> {{element.demandeur}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Observation">
                            <th mat-header-cell *matHeaderCellDef> Observations </th>
                            <td mat-cell *matCellDef="let element"> <mat-icon class="matIcon" (click)="ViewDetailsAudience(element)">visibility</mat-icon></td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></tr>
                        </table>
                      </div>
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </mat-tab>
            <mat-tab class="mattab" label="Archives">
              <div fxLayout="row" fxFlex="90" class="audiences" fxLayoutAlign="center" style="position: relative ; top: 40px">
                <mat-tab-group fxFlex="90" class="mat-elevation-z4" animationDuration="0ms">
                  <mat-tab *ngFor=" let mois1 of mois" label="{{mois1}}">
                    <div *ngIf="mois1 == 'Janvier'">
                      <div class="example-container mat-elevation-z8">
                        <table class="audiencesTable" mat-table [dataSource]="JanvierArchive">
                          <!-- Position Column -->
                          <ng-container matColumnDef="Jour">
                            <th mat-header-cell *matHeaderCellDef> Jour </th>
                            <td mat-cell *matCellDef="let element"> {{element.jour | date:'shortDate'}} </td>
                          </ng-container>

                          <!-- Name Column -->
                          <ng-container matColumnDef="Debut">
                            <th mat-header-cell *matHeaderCellDef> Début audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.debutAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Weight Column -->
                          <ng-container matColumnDef="Fin">
                            <th mat-header-cell *matHeaderCellDef> Fin audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.finAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Organisme">
                            <th mat-header-cell *matHeaderCellDef> Organisme ou personne demandeur </th>
                            <td mat-cell *matCellDef="let element"> {{element.demandeur}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Observation">
                            <th mat-header-cell *matHeaderCellDef> Observations </th>
                            <td mat-cell *matCellDef="let element"> <mat-icon class="matIcon" (click)="ViewDetailsAudience(element)">visibility</mat-icon> </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></tr>
                        </table>
                      </div>
                    </div>
                    <div *ngIf="mois1 == 'Fevrier'">
                      <div class="example-container mat-elevation-z8">
                        <table mat-table [dataSource]="FevrierArchive">
                          <!-- Position Column -->
                          <ng-container matColumnDef="Jour">
                            <th mat-header-cell *matHeaderCellDef> Jour </th>
                            <td mat-cell *matCellDef="let element"> {{element.jour | date:'shortDate'}} </td>
                          </ng-container>

                          <!-- Name Column -->
                          <ng-container matColumnDef="Debut">
                            <th mat-header-cell *matHeaderCellDef> Début audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.debutAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Weight Column -->
                          <ng-container matColumnDef="Fin">
                            <th mat-header-cell *matHeaderCellDef> Fin audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.finAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Organisme">
                            <th mat-header-cell *matHeaderCellDef> Organisme ou personne demandeur </th>
                            <td mat-cell *matCellDef="let element"> {{element.demandeur}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Observation">
                            <th mat-header-cell *matHeaderCellDef> Observations </th>
                            <td mat-cell *matCellDef="let element"> <mat-icon class="matIcon" (click)="ViewDetailsAudience(element)">visibility</mat-icon> </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></tr>
                        </table>
                      </div>
                    </div>
                    <div *ngIf="mois1 == 'Mars'">
                      <div class="example-container mat-elevation-z8">
                        <table mat-table [dataSource]="MarsArchive">
                          <!-- Position Column -->
                          <ng-container matColumnDef="Jour">
                            <th mat-header-cell *matHeaderCellDef> Jour </th>
                            <td mat-cell *matCellDef="let element"> {{element.jour | date:'shortDate'}} </td>
                          </ng-container>

                          <!-- Name Column -->
                          <ng-container matColumnDef="Debut">
                            <th mat-header-cell *matHeaderCellDef> Début audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.debutAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Weight Column -->
                          <ng-container matColumnDef="Fin">
                            <th mat-header-cell *matHeaderCellDef> Fin audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.finAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Organisme">
                            <th mat-header-cell *matHeaderCellDef> Organisme ou personne demandeur </th>
                            <td mat-cell *matCellDef="let element"> {{element.demandeur}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Observation">
                            <th mat-header-cell *matHeaderCellDef> Observations </th>
                            <td mat-cell *matCellDef="let element"> <mat-icon class="matIcon" (click)="ViewDetailsAudience(element)">visibility</mat-icon> </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></tr>
                        </table>
                      </div>
                    </div>
                    <div *ngIf="mois1 == 'Avril'">
                      <div class="example-container mat-elevation-z8">
                        <table mat-table [dataSource]="AvrilArchive">
                          <!-- Position Column -->
                          <ng-container matColumnDef="Jour">
                            <th mat-header-cell *matHeaderCellDef> Jour </th>
                            <td mat-cell *matCellDef="let element"> {{element.jour | date:'shortDate'}} </td>
                          </ng-container>

                          <!-- Name Column -->
                          <ng-container matColumnDef="Debut">
                            <th mat-header-cell *matHeaderCellDef> Début audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.debutAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Weight Column -->
                          <ng-container matColumnDef="Fin">
                            <th mat-header-cell *matHeaderCellDef> Fin audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.finAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Organisme">
                            <th mat-header-cell *matHeaderCellDef> Organisme ou personne demandeur </th>
                            <td mat-cell *matCellDef="let element"> {{element.demandeur}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Observation">
                            <th mat-header-cell *matHeaderCellDef> Observations </th>
                            <td mat-cell *matCellDef="let element"> <mat-icon class="matIcon" (click)="ViewDetailsAudience(element)">visibility</mat-icon> </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></tr>
                        </table>
                      </div>
                    </div>
                    <div *ngIf="mois1 == 'Mai'">
                      <div class="example-container mat-elevation-z8">
                        <table mat-table [dataSource]="MaiArchive">
                          <!-- Position Column -->
                          <ng-container matColumnDef="Jour">
                            <th mat-header-cell *matHeaderCellDef> Jour </th>
                            <td mat-cell *matCellDef="let element"> {{element.jour | date:'shortDate'}} </td>
                          </ng-container>

                          <!-- Name Column -->
                          <ng-container matColumnDef="Debut">
                            <th mat-header-cell *matHeaderCellDef> Début audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.debutAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Weight Column -->
                          <ng-container matColumnDef="Fin">
                            <th mat-header-cell *matHeaderCellDef> Fin audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.finAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Organisme">
                            <th mat-header-cell *matHeaderCellDef> Organisme ou personne demandeur </th>
                            <td mat-cell *matCellDef="let element"> {{element.demandeur}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Observation">
                            <th mat-header-cell *matHeaderCellDef> Observations </th>
                            <td mat-cell *matCellDef="let element"> <mat-icon class="matIcon" (click)="ViewDetailsAudience(element)">visibility</mat-icon> </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></tr>
                        </table>
                      </div>
                    </div>
                    <div *ngIf="mois1 == 'Juin'">
                      <div class="example-container mat-elevation-z8">
                        <table mat-table [dataSource]="JuinArchive">
                          <!-- Position Column -->
                          <ng-container matColumnDef="Jour">
                            <th mat-header-cell *matHeaderCellDef> Jour </th>
                            <td mat-cell *matCellDef="let element"> {{element.jour | date:'shortDate'}} </td>
                          </ng-container>

                          <!-- Name Column -->
                          <ng-container matColumnDef="Debut">
                            <th mat-header-cell *matHeaderCellDef> Début audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.debutAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Weight Column -->
                          <ng-container matColumnDef="Fin">
                            <th mat-header-cell *matHeaderCellDef> Fin audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.finAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Organisme">
                            <th mat-header-cell *matHeaderCellDef> Organisme ou personne demandeur </th>
                            <td mat-cell *matCellDef="let element"> {{element.demandeur}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Observation">
                            <th mat-header-cell *matHeaderCellDef> Observations </th>
                            <td mat-cell *matCellDef="let element"> <mat-icon class="matIcon" (click)="ViewDetailsAudience(element)">visibility</mat-icon> </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></tr>
                        </table>
                      </div>
                    </div>
                    <div *ngIf="mois1 == 'Juillet'">
                      <div class="example-container mat-elevation-z8">
                        <table mat-table [dataSource]="JuilletArchive">
                          <!-- Position Column -->
                          <ng-container matColumnDef="Jour">
                            <th mat-header-cell *matHeaderCellDef> Jour </th>
                            <td mat-cell *matCellDef="let element"> {{element.jour | date:'shortDate'}} </td>
                          </ng-container>

                          <!-- Name Column -->
                          <ng-container matColumnDef="Debut">
                            <th mat-header-cell *matHeaderCellDef> Début audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.debutAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Weight Column -->
                          <ng-container matColumnDef="Fin">
                            <th mat-header-cell *matHeaderCellDef> Fin audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.finAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Organisme">
                            <th mat-header-cell *matHeaderCellDef> Organisme ou personne demandeur </th>
                            <td mat-cell *matCellDef="let element"> {{element.demandeur}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Observation">
                            <th mat-header-cell *matHeaderCellDef> Observations </th>
                            <td mat-cell *matCellDef="let element"> <mat-icon class="matIcon" (click)="ViewDetailsAudience(element)">visibility</mat-icon> </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></tr>
                        </table>
                      </div>
                    </div>
                    <div *ngIf="mois1 == 'Aout'">
                      <div class="example-container mat-elevation-z8">
                        <table mat-table [dataSource]="AoutArchive">
                          <!-- Position Column -->
                          <ng-container matColumnDef="Jour">
                            <th mat-header-cell *matHeaderCellDef> Jour </th>
                            <td mat-cell *matCellDef="let element"> {{element.jour | date:'shortDate'}} </td>
                          </ng-container>

                          <!-- Name Column -->
                          <ng-container matColumnDef="Debut">
                            <th mat-header-cell *matHeaderCellDef> Début audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.debutAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Weight Column -->
                          <ng-container matColumnDef="Fin">
                            <th mat-header-cell *matHeaderCellDef> Fin audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.finAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Organisme">
                            <th mat-header-cell *matHeaderCellDef> Organisme ou personne demandeur </th>
                            <td mat-cell *matCellDef="let element"> {{element.demandeur}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Observation">
                            <th mat-header-cell *matHeaderCellDef> Observations </th>
                            <td mat-cell *matCellDef="let element"> <mat-icon class="matIcon" (click)="ViewDetailsAudience(element)">visibility</mat-icon> </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></tr>
                        </table>
                      </div>
                    </div>
                    <div *ngIf="mois1 == 'Septembre'">
                      <div class="example-container mat-elevation-z8">
                        <table mat-table [dataSource]="SeptembreArchive">
                          <!-- Position Column -->
                          <ng-container matColumnDef="Jour">
                            <th mat-header-cell *matHeaderCellDef> Jour </th>
                            <td mat-cell *matCellDef="let element"> {{element.jour | date:'shortDate'}} </td>
                          </ng-container>

                          <!-- Name Column -->
                          <ng-container matColumnDef="Debut">
                            <th mat-header-cell *matHeaderCellDef> Début audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.debutAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Weight Column -->
                          <ng-container matColumnDef="Fin">
                            <th mat-header-cell *matHeaderCellDef> Fin audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.finAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Organisme">
                            <th mat-header-cell *matHeaderCellDef> Organisme ou personne demandeur </th>
                            <td mat-cell *matCellDef="let element"> {{element.demandeur}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Observation">
                            <th mat-header-cell *matHeaderCellDef> Observations </th>
                            <td mat-cell *matCellDef="let element"> <mat-icon class="matIcon" (click)="ViewDetailsAudience(element)">visibility</mat-icon> </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></tr>
                        </table>
                      </div>
                    </div>
                    <div *ngIf="mois1 == 'Octobre'">
                      <div class="example-container mat-elevation-z8">
                        <table mat-table [dataSource]="OctobreArchive">
                          <!-- Position Column -->
                          <ng-container matColumnDef="Jour">
                            <th mat-header-cell *matHeaderCellDef> Jour </th>
                            <td mat-cell *matCellDef="let element"> {{element.jour | date:'shortDate'}} </td>
                          </ng-container>

                          <!-- Name Column -->
                          <ng-container matColumnDef="Debut">
                            <th mat-header-cell *matHeaderCellDef> Début audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.debutAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Weight Column -->
                          <ng-container matColumnDef="Fin">
                            <th mat-header-cell *matHeaderCellDef> Fin audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.finAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Organisme">
                            <th mat-header-cell *matHeaderCellDef> Organisme ou personne demandeur </th>
                            <td mat-cell *matCellDef="let element"> {{element.demandeur}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Observation">
                            <th mat-header-cell *matHeaderCellDef> Observations </th>
                            <td mat-cell *matCellDef="let element"> <mat-icon class="matIcon" (click)="ViewDetailsAudience(element)">visibility</mat-icon> </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></tr>
                        </table>
                      </div>
                    </div>
                    <div *ngIf="mois1 == 'Novembre'">
                      <div class="example-container mat-elevation-z8">
                        <table mat-table [dataSource]="NovembreArchive">
                          <!-- Position Column -->
                          <ng-container matColumnDef="Jour">
                            <th mat-header-cell *matHeaderCellDef> Jour </th>
                            <td mat-cell *matCellDef="let element"> {{element.jour | date:'shortDate'}} </td>
                          </ng-container>

                          <!-- Name Column -->
                          <ng-container matColumnDef="Debut">
                            <th mat-header-cell *matHeaderCellDef> Début audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.debutAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Weight Column -->
                          <ng-container matColumnDef="Fin">
                            <th mat-header-cell *matHeaderCellDef> Fin audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.finAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Organisme">
                            <th mat-header-cell *matHeaderCellDef> Organisme ou personne demandeur </th>
                            <td mat-cell *matCellDef="let element"> {{element.demandeur}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Observation">
                            <th mat-header-cell *matHeaderCellDef> Observations </th>
                            <td mat-cell *matCellDef="let element"> <mat-icon class="matIcon" (click)="ViewDetailsAudience(element)">visibility</mat-icon> </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></tr>
                        </table>
                      </div>
                    </div>
                    <div *ngIf="mois1 == 'Decembre'">
                      <div class="example-container mat-elevation-z8">
                        <table mat-table [dataSource]="DecembreArchive">
                          <!-- Position Column -->
                          <ng-container matColumnDef="Jour">
                            <th mat-header-cell *matHeaderCellDef> Jour </th>
                            <td mat-cell *matCellDef="let element"> {{element.jour | date:'shortDate'}} </td>
                          </ng-container>

                          <!-- Name Column -->
                          <ng-container matColumnDef="Debut">
                            <th mat-header-cell *matHeaderCellDef> Début audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.debutAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Weight Column -->
                          <ng-container matColumnDef="Fin">
                            <th mat-header-cell *matHeaderCellDef> Fin audience </th>
                            <td mat-cell *matCellDef="let element"> {{element.finAudience | date :'shortTime'}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Organisme">
                            <th mat-header-cell *matHeaderCellDef> Organisme ou personne demandeur </th>
                            <td mat-cell *matCellDef="let element"> {{element.demandeur}} </td>
                          </ng-container>

                          <!-- Symbol Column -->
                          <ng-container matColumnDef="Observation">
                            <th mat-header-cell *matHeaderCellDef> Observations </th>
                            <td mat-cell *matCellDef="let element"> <mat-icon class="matIcon" (click)="ViewDetailsAudience(element)">visibility</mat-icon> </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></tr>
                        </table>
                      </div>
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </mat-tab>
          </mat-tab-group>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
