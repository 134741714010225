<div class="mat-toolbar">
  <div fxLayout="row">
    <div fxFlex="10">
      <img class="logo" routerLink="/app-home-director" src="assets/images/logo.jpeg"/>
    </div>
    <div fxFlex="16">
      <button class="logoutButton" (click)="logout()"><mat-icon style="color: red">power_settings_new</mat-icon></button>
    </div>
  </div>
</div>
